/* Common Imports */

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
  Snackbar,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { DropzoneAreaBase } from "material-ui-dropzone";
import MuiIconButton from "@material-ui/core/IconButton";
import { SRLWrapper } from "simple-react-lightbox";
import CloseIcon from "@material-ui/icons/Close";
import categoryOptions from "../data/categoryOptions";
import priorityOptions from "../data/priorityOptions";
import statusOptions from "../data/statusOptions";
import languageOptions from "../../Leads/data/languageOptions";
import platformOptions from "../../Leads/data/platformOptions";

/* Data Imports */

import AppConfig from "../../../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: "1rem",
    width: "100%",
  },
  heading: {
    width: "100%",
    color: "#0D4972",
    fontWeight: "400",
    fontSize: "2rem",
    lineHeight: "3rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "2rem",
  },
  textFieldHalf: {
    width: "100%",
    margin: "0rem 0rem 2rem 0rem",
    [theme.breakpoints.up("md")]: {
      width: "48%",
    },
  },
  row: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cancelButton: {
    color: "#FFFFFF",
    background: "#727272",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#727272",
    },
  },
  deleteButton: {
    color: "#FFFFFF",
    background: "#2AA9E1",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#2AA9E1",
    },
  },
  updateButton: {
    color: "#FFFFFF",
    background: "#0D4972",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#0D4972",
    },
  },
  downloadButton: {
    color: "#FFFFFF",
    background: "#0D4972",
    textTransform: "none",
    margin: "2rem 0rem 2rem 0rem",
    "&:hover": {
      color: "#FFFFFF",
      background: "#0D4972",
    },
  },
  imageContainer: {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    gap: "1rem",
    cursor: "pointer",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  formControl: { width: "100%" },
}));

const options = {
  buttons: {
    iconPadding: "10px",
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: true,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: false,
    size: "45px",
  },
  thumbnails: {
    showThumbnails: true,
  },
  settings: {
    boxShadow: "none",
    disableKeyboardControls: true,
    disablePanzoom: false,
    disableWheelControls: true,
    hideControlsAfter: 3000,
    overlayColor: "rgba(30, 30, 30, 0.9)",
    usingPreact: false,
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UpdateTicketDetails = (props) => {
  const classes = useStyles();

  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [objectID, setObjectID] = React.useState("");
  const [ticket_id, setTicketID] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const [playerPlatform, setPlayerPlatform] = React.useState("");
  const [masterId, setMasterId] = React.useState("");
  const [priority, setPriority] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [agentassign, setAgentAssign] = React.useState([]);
  const [leadassign, setLeadAssign] = React.useState([]);
  const [assignedby_name, setAssignedByName] = React.useState("");
  const [assignedto_lead_id, setAssignedToLeadId] = React.useState("");
  const [assignedto_lead_name, setAssignedToLeadName] = React.useState("");
  const [assignedto_agent_id, setAssignedToAgentId] = React.useState("");
  const [assignedto_agent_name, setAssignedToAgentName] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [utr, setUtr] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [ifsc, setIfsc] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [accountHolderName, setAccountHolderName] = React.useState("");
  // const [images, setImages] = React.useState([]);
  const [upload, setUpload] = React.useState("image");
  const [url, setUrl] = React.useState([]);
  const [pdfUrl, setPdfUrl] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState(
    "Oops! Something seems to have gone wrong!!"
  );

  React.useEffect(async () => {
    const agentdata = await (
      await fetch(
        AppConfig.apiUrlPrefix + "finance-agent/finance-agent-details",
        {
          cache: "no-store",
        }
      )
    ).json();

    const leaddata = await (
      await fetch(
        AppConfig.apiUrlPrefix + "finance-lead/finance-lead-details",
        {
          cache: "no-store",
        }
      )
    ).json();

    setLeadAssign(leaddata);
    setAgentAssign(agentdata);
  }, []);

  React.useEffect(() => {
    if (props.updateData !== undefined) {
      setTicketID(props.updateData[0] ? props.updateData[0] : "");
      setUsername(props.updateData[2] ? props.updateData[2] : "");
      setPhone(props.updateData[3] ? props.updateData[3] : "");
      setLanguage(props.updateData[4] ? props.updateData[4] : "");
      setPlayerPlatform(props.updateData[5] ? props.updateData[5] : "");
      setMasterId(props.updateData[6] ? props.updateData[6] : "");
      setCategory(props.updateData[7] ? props.updateData[7] : "");
      setPriority(props.updateData[9] ? props.updateData[9] : "");
      setStatus(props.updateData[10] ? props.updateData[10] : "");
      setDescription(props.updateData[11] ? props.updateData[11] : "");
      setAssignedByName(props.updateData[13] ? props.updateData[13] : "");
      setAssignedToLeadId(props.updateData[14] ? props.updateData[14] : "");
      setAssignedToLeadName(props.updateData[15] ? props.updateData[15] : "");
      setAssignedToAgentId(props.updateData[16] ? props.updateData[16] : "");
      setAssignedToAgentName(props.updateData[17] ? props.updateData[17] : "");
      setAmount(props.updateData[20] ? props.updateData[20] : "");
      setUtr(props.updateData[21] ? props.updateData[21] : "");
      setBankName(props.updateData[22] ? props.updateData[22] : "");
      setIfsc(props.updateData[23] ? props.updateData[23] : "");
      setAccountHolderName(props.updateData[24] ? props.updateData[24] : "");
      setAccountNumber(props.updateData[25] ? props.updateData[25] : "");
      setUrl(props.updateData[26] ? props.updateData[26] : []);
      // setImages(props.updateData[22] ? props.updateData[22] : []);
      setObjectID(props.updateData[30] ? props.updateData[30] : "");
      setPdfUrl(props.updateData[31] ? props.updateData[31] : []);
    }
  }, [props.updateData]);

  const submitUpdate = () => {
    const reqURL =
      AppConfig.apiUrlPrefix +
      "tickets/update-ticket-details?assignedto_lead_id=" +
      encodeURIComponent(assignedto_lead_id) +
      "&assignedto_lead_name=" +
      encodeURIComponent(assignedto_lead_name) +
      "&assignedto_agent_id=" +
      encodeURIComponent(assignedto_agent_id) +
      "&assignedto_agent_name=" +
      encodeURIComponent(assignedto_agent_name) +
      "&username=" +
      encodeURIComponent(username) +
      "&phone=" +
      encodeURIComponent(phone) +
      "&language=" +
      encodeURIComponent(language) +
      "&masterId=" +
      encodeURIComponent(masterId) +
      "&player_platform=" +
      encodeURIComponent(playerPlatform) +
      "&category=" +
      encodeURIComponent(category) +
      "&priority=" +
      encodeURIComponent(priority) +
      "&status=" +
      encodeURIComponent(status) +
      "&amount=" +
      encodeURIComponent(amount) +
      "&utr=" +
      encodeURIComponent(utr) +
      "&bankName=" +
      encodeURIComponent(bankName) +
      "&ifsc=" +
      encodeURIComponent(ifsc) +
      "&accountHolderName=" +
      encodeURIComponent(accountHolderName) +
      "&accountNumber=" +
      encodeURIComponent(accountNumber) +
      "&description=" +
      encodeURIComponent(description) +
      "&comment=" +
      encodeURIComponent(comment) +
      "&role=" +
      encodeURIComponent(props.role) +
      "&updatedby_id=" +
      encodeURIComponent(props.updatedby_id) +
      "&updatedby_name=" +
      encodeURIComponent(props.updatedby_name) +
      "&ticket_id=" +
      encodeURIComponent(ticket_id) +
      "&_id=" +
      encodeURIComponent(objectID);
    setLoadingSnackbar(true);
    fetch(reqURL, {
      cache: "no-store",
      body: JSON.stringify({ images: url, pdf: pdfUrl }),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (data.status == "success") {
              setLoadingSnackbar(false);
              setSuccessSnackbar(true);
              typeof props.onUpdate === "function"
                ? props.onUpdate(data)
                : undefined;
            } else if (data.status == "failure") {
              setErrorMsg(data.error);
              setLoadingSnackbar(false);
              setErrorSnackbar(true);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoadingSnackbar(false);
            setErrorSnackbar(true);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoadingSnackbar(false);
        setErrorSnackbar(true);
      });
  };

  const onDelete = (props) => {
    const reqURL = encodeURI(
      AppConfig.apiUrlPrefix + "tickets/delete-ticket-details?_id=" + objectID
    );
    fetch(reqURL, { cache: "no-store" })
      .then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            typeof props.onDelete === "function"
              ? props.onDelete(data)
              : undefined;
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  const handleDownload = () => {
    const downloadnext = (i) => {
      if (i >= pdfUrl.length) {
        return;
      }
      const a = document.createElement("a");
      a.href = pdfUrl[i];
      a.target = "_parent";
      // Use a.download if available, it prevents plugins from opening.
      if ("download" in a) {
        a.download = "ticket-file.pdf";
      }
      // Add a to the doc for click to work.
      (document.body || document.documentElement).appendChild(a);
      if (a.click) {
        a.click(); // The click method is supported by most browsers.
      } else {
        $(a).click(); // Backup using jquery
      }
      // Delete the temporary link.
      a.parentNode.removeChild(a);
      // Download the next file with a small timeout. The timeout is necessary
      // for IE, which will otherwise only download the first file.
      setTimeout(() => {
        downloadnext(i + 1);
      }, 1000);
    };
    // Initiate the first download.
    downloadnext(0);
  };

  const handleFileSelect = async (filey) => {
    if (filey.length > 0) {
      const files = filey[0].file;
      const formData = new FormData();
      formData.append("files", files);
      const res = (
        await (
          await fetch(AppConfig.apiUrlPrefix + "tickets/upload-image", {
            method: "POST",
            body: formData,
          })
        ).json()
      ).objectLink;
      setUrl((url) => [...url, res]);
    }
  };

  const handlePdfSelect = async (filey) => {
    if (filey.length > 0) {
      const files = filey[0].file;
      const formData = new FormData();
      formData.append("files", files);
      const res = (
        await (
          await fetch(AppConfig.apiUrlPrefix + "tickets/upload-pdf", {
            method: "POST",
            body: formData,
          })
        ).json()
      ).objectLink;
      setPdfUrl((pdfUrl) => [...pdfUrl, res]);
    }
  };

  return (
    <React.Fragment>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={3000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Updating Ticket...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={500}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Successfully Updated.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="warning">
          {errorMsg}
        </Alert>
      </Snackbar>
      <Dialog
        className={classes.modal}
        onClose={props.onClose}
        aria-labelledby="update-ticket-modal"
        open={props.open}
        maxWidth="lg"
      >
        <DialogTitle id="update-ticket-modal-title" onClose={props.onClose}>
          <Typography variant="h5" component="p" className={classes.heading}>
            Update Ticket
          </Typography>
          <MuiIconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.onClose}
          >
            <CloseIcon />
          </MuiIconButton>
        </DialogTitle>
        <form method="GET" id="atf">
          <DialogContent dividers>
            <input
              id="ticket_id"
              type="hidden"
              name="ticket_id"
              value={ticket_id}
              onChange={(event) => {
                setTicketID(event.target.value);
              }}
            />
            <TextField
              id="assigned_by"
              name="assigned_by"
              label="Assigned By"
              type="text"
              size="small"
              className={classes.textField}
              value={assignedby_name}
              disabled
            />
            {props.role == "admin" || props.role == "manager" ? (
              <FormControl size="small" className={classes.formControl}>
                <InputLabel htmlFor="assign-lead-select">
                  Assign To Lead
                </InputLabel>
                <Select
                  native
                  label="assign to lead"
                  className={classes.textField}
                  value={
                    assignedto_lead_id + "/~*42069*~/" + assignedto_lead_name
                  }
                  onChange={(event) => {
                    setAssignedToLeadId(
                      event.target.value.split("/~*42069*~/")[0]
                    );
                    setAssignedToLeadName(
                      event.target.value.split("/~*42069*~/")[1]
                    );
                  }}
                >
                  <option value=""></option>
                  {leadassign.map((leaddata, key) => (
                    <option
                      key={key}
                      value={leaddata.id + "/~*42069*~/" + leaddata.name}
                    >
                      {leaddata.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            {props.role == "finance lead" ||
            props.role == "admin" ||
            props.role == "team lead" ||
            props.role == "manager" ? (
              <FormControl size="small" className={classes.formControl}>
                <InputLabel htmlFor="assign-agent-select">
                  Assign To Agent
                </InputLabel>
                <Select
                  native
                  label="assign to agent"
                  className={classes.textField}
                  value={
                    assignedto_agent_id + "/~*42069*~/" + assignedto_agent_name
                  }
                  onChange={(event) => {
                    setAssignedToAgentId(
                      event.target.value.split("/~*42069*~/")[0]
                    );
                    setAssignedToAgentName(
                      event.target.value.split("/~*42069*~/")[1]
                    );
                  }}
                >
                  <option value=""></option>
                  {agentassign.map((agentdata, key) => (
                    <option
                      key={key}
                      value={agentdata.id + "/~*42069*~/" + agentdata.name}
                    >
                      {agentdata.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            <TextField
              id="username"
              name="username"
              label="Username"
              type="text"
              size="small"
              className={classes.textField}
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
              }}
              disabled={
                props.role == "manager" ||
                props.role == "admin" ||
                props.role == "team lead"
                  ? false
                  : true
              }
            />
            <TextField
              id="phone"
              name="phone"
              label="Phone Number"
              type="text"
              size="small"
              className={classes.textField}
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
              disabled={
                props.role == "manager" ||
                props.role == "admin" ||
                props.role == "team lead"
                  ? false
                  : true
              }
            />
            <TextField
              id="master id"
              name="master id"
              label="Master ID"
              type="text"
              size="small"
              className={classes.textField}
              value={masterId}
              onChange={(event) => {
                setMasterId(event.target.value);
              }}
              disabled={
                props.role == "manager" ||
                props.role == "admin" ||
                props.role == "team lead"
                  ? false
                  : true
              }
            />
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="language-select">Language</InputLabel>
              <Select
                native
                label="Language"
                className={classes.textField}
                value={language}
                onChange={(event) => {
                  setLanguage(event.target.value);
                }}
                disabled={
                  props.role == "manager" ||
                  props.role == "admin" ||
                  props.role == "team lead"
                    ? false
                    : true
                }
              >
                <option value=""></option>
                {languageOptions.map((option) => (
                  <option key={option.title} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="player-platform-select">
                Player Platform
              </InputLabel>
              <Select
                native
                label="Player Platform"
                className={classes.textField}
                value={playerPlatform}
                onChange={(event) => {
                  setPlayerPlatform(event.target.value);
                }}
                disabled={
                  props.role == "manager" ||
                  props.role == "admin" ||
                  props.role == "team lead"
                    ? false
                    : true
                }
              >
                <option value=""></option>
                {platformOptions.map((option) => (
                  <option key={option.title} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel htmlFor="category-select">Category</InputLabel>
              <Select
                native
                label="Category"
                className={classes.textField}
                value={category}
                onChange={(event) => {
                  setCategory(event.target.value);
                }}
                disabled={
                  props.role == "manager" ||
                  props.role == "admin" ||
                  props.role == "team lead"
                    ? false
                    : true
                }
              >
                <option value=""></option>
                {categoryOptions.map((option, key) => (
                  <option key={key} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel htmlFor="priority-select">Priority</InputLabel>
              <Select
                native
                label="Priority"
                className={classes.textField}
                value={priority}
                onChange={(event) => {
                  setPriority(event.target.value);
                }}
                disabled={
                  props.role == "manager" ||
                  props.role == "admin" ||
                  props.role == "team lead"
                    ? false
                    : true
                }
              >
                <option value=""></option>
                {priorityOptions.map((option, key) => (
                  <option key={key} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small" className={classes.formControl}>
              <InputLabel htmlFor="status-select">Status</InputLabel>
              <Select
                native
                label="Status"
                value={status}
                className={classes.textField}
                onChange={(event) => {
                  setStatus(event.target.value);
                }}
                disabled={props.role == "agent" ? true : false}
              >
                <option value=""></option>
                {statusOptions.map((option, key) => (
                  <option key={key} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>

            <div>
              <div className={classes.row}>
                <TextField
                  id="amount"
                  name="amount"
                  label="Amount"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  size="small"
                  value={amount}
                  className={classes.textFieldHalf}
                  onChange={(event) => {
                    setAmount(event.target.value);
                  }}
                  disabled={
                    props.role == "manager" ||
                    props.role == "admin" ||
                    props.role == "team lead" ||
                    (props.role == "finance agent" &&
                      category == "OP Withdrawal") ||
                    (props.role == "finance agent" &&
                      category == "Withdrawal Request") ||
                    (props.role == "finance agent" &&
                      category == "Withdraw Issue")
                      ? false
                      : true
                  }
                />
                <TextField
                  id="utr_number"
                  name="utr_number"
                  label="UTR Number"
                  type="text"
                  size="small"
                  value={utr}
                  className={classes.textFieldHalf}
                  onChange={(event) => {
                    setUtr(event.target.value.replace(/\s/g, ""));
                  }}
                  disabled={
                    props.role == "manager" ||
                    props.role == "admin" ||
                    props.role == "team lead" ||
                    (props.role == "finance agent" &&
                      category == "OP Withdrawal") ||
                    (props.role == "finance agent" &&
                      category == "Withdrawal Request") ||
                    (props.role == "finance agent" &&
                      category == "Withdraw Issue")
                      ? false
                      : true
                  }
                />
              </div>
              {props.role == "admin" ||
              props.role == "finance agent" ||
              props.role == "team lead" ? (
                <>
                  <div className={classes.row}>
                    <TextField
                      id="bank_name"
                      name="bank_name"
                      label="Bank Name"
                      type="text"
                      size="small"
                      value={bankName}
                      className={classes.textFieldHalf}
                      onChange={(event) => {
                        setBankName(event.target.value);
                      }}
                      disabled={
                        props.role == "manager" ||
                        props.role == "admin" ||
                        props.role == "team lead"
                          ? false
                          : true
                      }
                    />
                    <TextField
                      id="ifsc_code"
                      name="ifsc_code"
                      label="IFSC Code"
                      type="text"
                      size="small"
                      value={ifsc}
                      className={classes.textFieldHalf}
                      onChange={(event) => {
                        setIfsc(event.target.value);
                      }}
                      disabled={
                        props.role == "manager" ||
                        props.role == "admin" ||
                        props.role == "team lead"
                          ? false
                          : true
                      }
                    />
                  </div>
                  <div className={classes.row}>
                    <TextField
                      id="account_holder_name"
                      name="account_holder_name"
                      label="Account Holder Name"
                      type="text"
                      size="small"
                      value={accountHolderName}
                      className={classes.textFieldHalf}
                      onChange={(event) => {
                        setAccountHolderName(event.target.value);
                      }}
                      disabled={
                        props.role == "manager" ||
                        props.role == "admin" ||
                        props.role == "team lead"
                          ? false
                          : true
                      }
                    />
                    <TextField
                      id="account_number"
                      name="account_number"
                      label="Account Number"
                      type="text"
                      size="small"
                      value={accountNumber}
                      className={classes.textFieldHalf}
                      onChange={(event) => {
                        setAccountNumber(event.target.value);
                      }}
                      disabled={
                        props.role == "manager" ||
                        props.role == "admin" ||
                        props.role == "team lead"
                          ? false
                          : true
                      }
                    />
                  </div>
                </>
              ) : null}
            </div>

            <TextField
              id="description"
              name="description"
              label="Description"
              type="text"
              size="small"
              className={classes.textField}
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              disabled={
                props.role == "manager" ||
                props.role == "admin" ||
                props.role == "team lead"
                  ? false
                  : true
              }
            />
            <TextField
              id="comment"
              name="comment"
              label="Comment"
              type="text"
              size="small"
              required={props.role == "finance agent" ? true : false}
              className={classes.textField}
              onChange={(event) => {
                setComment(event.target.value);
              }}
            />
            <RadioGroup
              aria-label="upload"
              name="Upload"
              value={upload}
              onChange={(event) => {
                setUpload(event.target.value);
              }}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value="image"
                control={<Radio color="primary" />}
                label="Image"
              />
              <FormControlLabel
                value="pdf"
                control={<Radio color="primary" />}
                label="PDF"
              />
            </RadioGroup>
            {upload === "image" ? (
              <DropzoneAreaBase
                filesLimit={1}
                acceptedFiles={["image/*"]}
                dropzoneText={"Drag and drop images here or click"}
                onAdd={handleFileSelect}
                maxFileSize={104857600}
                showPreviews={true}
              />
            ) : (
              <DropzoneAreaBase
                filesLimit={1}
                acceptedFiles={["application/pdf"]}
                dropzoneText={"Drag and drop files here or click"}
                onAdd={handlePdfSelect}
                maxFileSize={104857600}
                showPreviews={true}
              />
            )}
            <SRLWrapper options={options}>
              <div className={classes.imageContainer}>
                {url.length > 0
                  ? url.map((image, key) => (
                      <img src={image} height="100px" width="100px" key={key} />
                    ))
                  : null}
              </div>
            </SRLWrapper>
            {pdfUrl.length > 0 ? (
              <Button
                variant="contained"
                label="Download PDF"
                onClick={handleDownload}
                className={classes.downloadButton}
              >
                Download PDF
              </Button>
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              label="Close"
              onClick={props.onClose}
              className={classes.cancelButton}
            >
              Close
            </Button>
            {props.role == "admin" ? (
              <Button
                variant="contained"
                label="Delete"
                onClick={onDelete}
                className={classes.deleteButton}
              >
                Delete
              </Button>
            ) : null}
            <Button
              variant="contained"
              title="Submit"
              onClick={submitUpdate}
              rel="noopener"
              className={classes.updateButton}
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default UpdateTicketDetails;
