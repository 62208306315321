const priorityOptions = [
  {
    title: "Affiliate",
    value: "Affiliate",
  },
  { title: "Regular", value: "Regular" },
  { title: "VIP", value: "VIP" },
];

export default priorityOptions;
