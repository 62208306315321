import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  text: {
    color: "#5A5A5A",
  },
  subText: {
    paddingBottom: "0.5rem",
  },
});

const NotificationCard = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body2" component="p" color="secondary">
        Ticket ID : {props.ticket_id ? props.ticket_id : ""}
        {props.username !== "" && props.username !== undefined ? (
          <div>Username : {props.username ? props.username : ""}</div>
        ) : (
          ""
        )}
      </Typography>

      {props.status && props.status == "Open" ? (
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.text}
        >
          A new ticket is assigned to you
        </Typography>
      ) : null}

      {props.status && props.status !== "Open" ? (
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.text}
        >
          {props.updated_by ? props.updated_by : ""}
          {props.status ? " updated status to " : ""}
          {props.status ? '"' + props.status + '"' : ""}
        </Typography>
      ) : null}

      {props.comment ? (
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.text}
        >
          {props.updated_by ? props.updated_by : ""}
          {props.comment ? " commented " : ""}
          {props.comment ? '"' + props.comment + '"' : ""}
        </Typography>
      ) : null}

      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.subText}
      >
        {props.created_at ? props.created_at : ""}
      </Typography>
      <Divider />
    </div>
  );
};

export default NotificationCard;
