import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import AddAgent from "../admin/new/sub-components/AddAgent";
import LeadForm from "../agent/Leads/LeadForm";

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
}));

const AddNew = (props) => {
  const classes = useStyles();

  const [add, setAdd] = React.useState("add agent");

  return (
    <React.Fragment>
      <RadioGroup
        aria-label="add"
        name="Add"
        value={add}
        onChange={(event) => {
          setAdd(event.target.value);
        }}
        className={classes.radioGroup}
      >
        <FormControlLabel
          value="add agent"
          control={<Radio color="primary" />}
          label="Add Agent"
        />
        <FormControlLabel
          value="add user"
          control={<Radio color="primary" />}
          label="Add User"
        />
      </RadioGroup>

      {add === "add agent" ? (
        <AddAgent
          addedby_id={props.addedby_id}
          addedby_name={props.addedby_name}
          role={props.role}
        />
      ) : (
        <span></span>
      )}
      {add === "add user" ? (
        <LeadForm
          user_id={props.addedby_id}
          username={props.addedby_name}
          assignedto_platform={props.assignedto_platform}
          role={props.role}
        />
      ) : (
        <span></span>
      )}
    </React.Fragment>
  );
};

export default AddNew;
