/* Common Imports */

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import MuiIconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { SRLWrapper } from "simple-react-lightbox";
import Stepper from "../../../../../components/stepper/Stepper";

/* Data Imports */

import AppConfig from "../../../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: "1rem",
    width: "100%",
  },
  heading: {
    width: "100%",
    color: "#0D4972",
    fontWeight: "400",
    fontSize: "2rem",
    lineHeight: "3rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  subHeading: {
    color: "#0D4972",
  },
  infoText: {
    width: "100%",
    color: "#727272",
    fontWeight: "600",
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    marginBottom: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
  },
  downloadButton: {
    color: "#FFFFFF",
    background: "#0D4972",
    textTransform: "none",
    margin: "2rem 0rem 2rem 0rem",
    "&:hover": {
      color: "#FFFFFF",
      background: "#0D4972",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cancelButton: {
    color: "#FFFFFF",
    background: "#727272",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#727272",
    },
  },
  imageContainer: {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    gap: "1rem",
    cursor: "pointer",
  },
  formControl: { width: "100%" },
}));

const options = {
  buttons: {
    iconPadding: "10px",
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: true,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: false,
    size: "45px",
  },
  thumbnails: {
    showThumbnails: true,
  },
  settings: {
    boxShadow: "none",
    disableKeyboardControls: true,
    disablePanzoom: false,
    disableWheelControls: true,
    hideControlsAfter: 3000,
    overlayColor: "rgba(30, 30, 30, 0.9)",
    usingPreact: false,
  },
};

const TicketDetailsModal = (props) => {
  const classes = useStyles();

  const [pdf, setPdf] = React.useState([]);
  const [ticketUpdates, setTicketUpdates] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(async () => {
    setLoading(true);
    const updates = await (
      await fetch(
        AppConfig.apiUrlPrefix + "ticket-updates/" + props.rowData[0],
        {
          cache: "no-store",
        }
      )
    ).json();

    setTicketUpdates(updates);
    setPdf(props.rowData[31] ? props.rowData[31] : []);
    setLoading(false);
  }, [props.rowData[0]]);

  const handleDownload = () => {
    const downloadnext = (i) => {
      if (i >= pdf.length) {
        return;
      }
      const a = document.createElement("a");
      a.href = pdf[i];
      a.target = "_parent";
      // Use a.download if available, it prevents plugins from opening.
      if ("download" in a) {
        a.download = "ticket-file.pdf";
      }
      // Add a to the doc for click to work.
      (document.body || document.documentElement).appendChild(a);
      if (a.click) {
        a.click(); // The click method is supported by most browsers.
      } else {
        $(a).click(); // Backup using jquery
      }
      // Delete the temporary link.
      a.parentNode.removeChild(a);
      // Download the next file with a small timeout. The timeout is necessary
      // for IE, which will otherwise only download the first file.
      setTimeout(() => {
        downloadnext(i + 1);
      }, 1000);
    };
    // Initiate the first download.
    downloadnext(0);
  };

  return (
    <React.Fragment>
      <Dialog
        className={classes.modal}
        onClose={props.onClose}
        aria-labelledby="ticket-details-modal"
        open={props.open}
        maxWidth="lg"
      >
        <DialogTitle id="ticket-details-modal-title" onClose={props.onClose}>
          <Typography variant="h5" component="p" className={classes.heading}>
            Ticket Details
          </Typography>
          <MuiIconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.onClose}
          >
            <CloseIcon />
          </MuiIconButton>
        </DialogTitle>
        <form method="GET" id="tdf">
          <DialogContent dividers>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Ticket ID : </span>
              {props.rowData[0]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>User ID : </span>
              {props.rowData[1]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Username : </span>
              {props.rowData[2]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Phone : </span>
              {props.rowData[3]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Language : </span>
              {props.rowData[4]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Player Platform : </span>
              {props.rowData[5]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Master ID : </span>
              {props.rowData[6]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Category : </span>
              {props.rowData[7]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Priority : </span>
              {props.rowData[9]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Status : </span>
              {props.rowData[10]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Description : </span>
              {props.rowData[11]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Assigned By : </span>
              {props.rowData[13]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Assigned To Lead : </span>
              {props.rowData[15]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Assigned To Agent : </span>
              {props.rowData[17]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Closed By : </span>
              {props.rowData[19]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Amount : </span>
              {props.rowData[20]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>UTR Number : </span>
              {props.rowData[21]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Bank Name : </span>
              {props.rowData[22]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>IFSC Code : </span>
              {props.rowData[23]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Account Holder Name : </span>
              {props.rowData[24]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Account Number : </span>
              {props.rowData[25]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Created At : </span>
              {props.rowData[27]}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.infoText}
            >
              <span className={classes.subHeading}>Closed At : </span>
              {props.rowData[28]}
            </Typography>
            <SRLWrapper options={options}>
              <div className={classes.imageContainer}>
                {props.rowData[26]
                  ? props.rowData[26].map((image, key) => (
                      <img src={image} height="100px" width="100px" key={key} />
                    ))
                  : null}
              </div>
            </SRLWrapper>
            {pdf.length > 0 ? (
              <Button
                variant="contained"
                label="Download PDF"
                onClick={handleDownload}
                className={classes.downloadButton}
              >
                Download PDF
              </Button>
            ) : null}
            {loading ? (
              <div>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </div>
            ) : (
              <Stepper updatesData={ticketUpdates} />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              label="Close"
              onClick={props.onClose}
              className={classes.cancelButton}
            >
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default TicketDetailsModal;
