import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DataCard from "../../../../components/cards/DataCard";
import DashboardTabs from "./sub-components/DashboardTabs";
import AppConfig from "../../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    gap: "1rem",
    paddingBottom: "2rem",
  },
  managers: {
    background: "#2AA9E1",
  },
  leads: {
    backgroundColor: "#EA7575",
  },
  agents: {
    backgroundColor: "#2AE194",
  },
  new: {
    backgroundColor: "#2AA9E1",
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();

  const [totalManagers, setTotalManagers] = React.useState(0);
  const [totalTeamLeads, setTotalTeamLeads] = React.useState(0);
  const [totalAgents, setTotalAgents] = React.useState(0);
  const [newAgents, setNewAgents] = React.useState(0);
  // const [refreshToken, setRefreshToken] = React.useState(Math.random());

  React.useEffect(async () => {
    const { total_agents, total_managers, total_team_leads, new_agents } =
      await (
        await fetch(AppConfig.apiUrlPrefix + "admin-dashboard/total-count", {
          cache: "no-store",
        })
      ).json();
    setTotalAgents(total_agents);
    setTotalManagers(total_managers);
    setTotalTeamLeads(total_team_leads);
    setNewAgents(new_agents);

    // const timer = setTimeout(() => setRefreshToken(Math.random()), 3000);

    // return () => clearTimeout(timer);
  }, []);

  return (
    <React.Fragment>
      <div className={classes.flex}>
        <DataCard
          title="Total Managers"
          subtitle={totalManagers}
          icon="/images/icons/admin/manager.svg"
          cardClass={classes.managers}
        />

        <DataCard
          title="Total Team Leads"
          subtitle={totalTeamLeads}
          icon="/images/icons/admin/teamlead.svg"
          cardClass={classes.leads}
        />
        <DataCard
          title="Total Agents"
          subtitle={totalAgents}
          icon="/images/icons/admin/agent.svg"
          cardClass={classes.agents}
        />
        <DataCard
          title="New Agents"
          subtitle={newAgents}
          icon="/images/icons/admin/newagent.svg"
          cardClass={classes.new}
        />
      </div>
      <DashboardTabs
        assignedby_id={props.assignedby_id}
        assignedby_name={props.assignedby_name}
        role={props.role}
        agents={props.agents}
        financeAgents={props.financeAgents}
      />
    </React.Fragment>
  );
};

export default Dashboard;
