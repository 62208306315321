import React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import ReadableDateString from "../../../../lib/ReadableDateString";
import AppConfig from "../../../../AppConfig";

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "#FAFAFA !important",
        },
      },
      MuiButton: {
        root: { color: "#4A4A4A", fontWeight: "600" },
      },
      MuiTableSortLabel: {
        root: {
          "&$active": {
            color: "#0D4972",
            "&& $icon": {
              color: "#0D4972",
            },
          },
        },
      },
      MUIDataTableBodyCell: {
        root: {
          color: "#727272",
          fontSize: "1rem",
        },
      },
      MuiPaper: { root: { color: "#0D4972" } },
      MUIDataTableToolbar: {
        root: {
          backgroundColor: "#EDEDED",
        },
      },
    },
  });

const LogsTable = (props) => {
  const [logData, setLogData] = React.useState([[]]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [logType, setLogType] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetch(
        AppConfig.apiUrlPrefix +
          "login-logout-logs?page=" +
          currentPage +
          "&limit=" +
          pageSize +
          "&user_id=" +
          props.user_id,
        {
          cache: "no-store",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            logType: logType,
          }),
        }
      )
        .then((r) => r.json())
        .then((d) => {
          setLogData(d.logData);
          setTotalCount(d.count);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    };
    fetchData();
  }, [currentPage, pageSize, logType]);

  const columns = [
    {
      name: "Log Type",
      options: {
        filterOptions: {
          names: ["login", "logout"],
        },
      },
    },
    { name: "Timings", options: { filter: false } },
  ];

  const options = {
    print: false,
    search: false,
    download: false,
    selectableRows: "none",
    rowsPerPageOptions: [5, 10, 15, 20],
    rowsPerPage: pageSize,
    page: currentPage - 1,
    count: totalCount,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Sorry, there is no data to display"
        ),
      },
    },
    onChangePage: (page) => {
      setCurrentPage(page + 1);
    },
    onChangeRowsPerPage: (pageLimit) => {
      setPageSize(pageLimit);
    },
    onFilterChange: (filterList, filterData) => {
      setLogType(filterData[0]);
    },
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      {typeof window !== "undefined" && (
        <MUIDataTable
          title={"Login History"}
          data={
            !loading
              ? logData.map((log) => {
                  return [
                    log.log_type,
                    log.logged_at ? ReadableDateString(log.logged_at) : "",
                  ];
                })
              : []
          }
          columns={columns}
          options={options}
        />
      )}
    </ThemeProvider>
  );
};

export default LogsTable;
