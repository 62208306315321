import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import PropTypes from "prop-types";
import {
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  Tabs,
  Tab,
  Box,
  Menu,
  MenuItem,
  // Badge,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import HistoryIcon from "@material-ui/icons/History";
// import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
// import AddFinanceAgent from "../../page-sections/admin/new/sub-components/AddFinanceAgent";
import Tickets from "../../page-sections/finance-lead/Tickets";
import LogsTable from "../../page-sections/agent/logs/LogsTable";
import AppConfig from "../../../AppConfig";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  logo: {
    marginRight: "0.5rem",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  toolbarIcons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    width: "50%",
    padding: theme.spacing(3),
  },
  iconLabelWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "row",
    gap: "1.5rem",
    paddingLeft: "0.5rem",
    fontSize: "1rem",
    fontWeight: "600",
    textTransform: "none",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0rem",
    },
  },
  tabIcon: { fontSize: "2rem" },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const FinanceLeadNavbar = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuId = "primary-notification-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      style={{ marginTop: "2rem" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem disabled>{props.username ? props.username : "User"}</MenuItem>
      <MenuItem
        onClick={async (e) => {
          await fetch(
            encodeURI(
              AppConfig.apiUrlPrefix +
                "logout-time?user_id=" +
                props.user_id +
                "&username=" +
                props.username +
                "&role=" +
                props.role
            ),
            {
              method: "POST",
              cache: "no-store",
            }
          );
          props.handleRemoveCookies();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.toolbarContent}>
            <Typography variant="h6" component="span">
              Aone Technology
            </Typography>
          </div>

          <div className={classes.toolbarIcons}>
            {/* <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {/* <img
            src="./images/logo/logo.svg"
            alt="logo"
            width={120}
            height={50}
            loading="eager"
            referrerPolicy="no-referrer"
            className={classes.logo}
          /> */}

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="sidebar"
          className={classes.tabs}
        >
          <Tab
            classes={{
              wrapper: classes.iconLabelWrapper,
            }}
            icon={<HomeOutlinedIcon className={classes.tabIcon} />}
            label="Dashboard"
            {...a11yProps(0)}
          />
          {/* <Tab
            classes={{
              wrapper: classes.iconLabelWrapper,
            }}
            icon={<AddCircleOutlineOutlinedIcon className={classes.tabIcon} />}
            label="Add New"
            {...a11yProps(1)}
          /> */}
          <Tab
            classes={{
              wrapper: classes.iconLabelWrapper,
            }}
            icon={<HistoryIcon className={classes.tabIcon} />}
            label="Login History"
            {...a11yProps(1)}
          />
        </Tabs>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <TabPanel value={value} index={0}>
          <Tickets
            user_id={props.user_id}
            role={props.role}
            username={props.username}
            assignedto_platform={props.assignedto_platform}
          />
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          <AddFinanceAgent
            addedby_id={props.user_id}
            addedby_name={props.username}
            role={props.role}
          />
        </TabPanel> */}
        <TabPanel value={value} index={1}>
          <LogsTable user_id={props.user_id} />
        </TabPanel>
      </main>
    </div>
  );
};

export default FinanceLeadNavbar;
