import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TextField,
  Typography,
  Select,
  FormControl,
  // Checkbox,
  // FormControlLabel,
  InputLabel,
  Button,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import AppConfig from "../../../../AppConfig";
import statusOptions from "./data/statusOptions";
import languageOptions from "./data/languageOptions";
import categoryOptions from "./data/categoryOptions";
import playerOptions from "./data/leadPlayerOptions";
import platformOptions from "./data/platformOptions";
// import gameOptions from "./data/gameOptions";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "1.2rem",
    fontWeight: "600",
    color: "#0D4972",
    paddingBottom: "1rem",
  },
  subText: {
    fontSize: "0.8rem",
    fontWeight: "600",
    color: "#0D4972",
    paddingBottom: "1rem",
  },
  textField: {
    marginBottom: "1rem",
    width: "100%",
  },
  paperStyle: {
    borderRadius: "10px",
    padding: "2rem 1.5rem 2rem 1.5rem",
    marginBottom: "1rem",
    width: "100%",
  },
  leadForm: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    gap: "2rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  formControl: { width: "100%" },
  checkboxRow: {
    paddingLeft: "0.6rem",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.up("lg")]: {
      alignItems: "center",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "2rem",
  },
  cancelButton: {
    marginRight: "1rem",
    color: "#FFFFFF",
    background: "#727272",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#727272",
    },
  },
  linkButton: {
    marginRight: "1rem",
    color: "#FFFFFF",
    background: "#2AA9E1",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#2AA9E1",
    },
  },
  createButton: {
    color: "#FFFFFF",
    background: "#0D4972",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#0D4972",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LeadForm = (props) => {
  const classes = useStyles();

  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [phone, setPhone] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [account_username, setAccountUsername] = React.useState("");
  const [name, setName] = React.useState("");
  const [alternate, setAlternatePhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [player, setPlayer] = React.useState("");
  const [playerPlatform, setPlayerPlatform] = React.useState("");
  const [city, setCity] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState("");
  // const [platform, setPlatform] = React.useState("");
  // const [game, setGame] = React.useState([]);
  // const [customSMS, setCustomSMS] = React.useState("");
  // const [sms, setSMS] = React.useState(false);
  const [addedby_id] = React.useState(props.user_id);
  const [addedby_name] = React.useState(props.username);
  const [masterId, setMasterId] = React.useState("");
  const [depositBank, setDepositBank] = React.useState("");
  const [bN1, setBN1] = React.useState("");
  const [ifsc1, setIfsc1] = React.useState("");
  const [aN1, setAN1] = React.useState("");
  const [aHN1, setAHN1] = React.useState("");
  const [bN2, setBN2] = React.useState("");
  const [ifsc2, setIfsc2] = React.useState("");
  const [aN2, setAN2] = React.useState("");
  const [aHN2, setAHN2] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState(
    "Oops! Something seems to have gone wrong!!"
  );
  // const [refreshToken, setRefreshToken] = React.useState(Math.random());

  const submitClear = () => {
    setPhone("");
    setStatus("");
    setName("");
    setAlternatePhone("");
    setEmail("");
    setDob("");
    setLanguage("");
    setCategory("");
    setPlayer("");
    setPlayerPlatform("");
    setDepositBank("");
    setMasterId("");
    setCity("");
    setPincode("");
    setBN1("");
    setIfsc1("");
    setAN1("");
    setAHN1("");
    setBN2("");
    setIfsc2("");
    setAN2("");
    setAHN2("");
    setMessage("");
    // setCustomSMS("");
  };

  // React.useEffect(async () => {
  //   if (props.role == "team lead") {
  //     setGame(gameOptions);
  //   } else {
  //     const data = await (
  //       await fetch(AppConfig.apiUrlPrefix + "agents/" + props.user_id, {
  //         cache: "no-store",
  //       })
  //     ).json();
  //     setGame(data.platform);
  //   }

  // const timer = setTimeout(() => setRefreshToken(Math.random()), 500);

  // return () => clearTimeout(timer);
  // }, []);

  // const submitSend = () => {
  //   const reqURL = encodeURI(
  //     AppConfig.apiUrlPrefix +
  //       "store-sms?phone=" +
  //       phone +
  //       "&sms_message=" +
  //       (sms == true
  //         ? customSMS
  //         : "Hello! Thank you for showing your Interest. Kindly install the App and start playing. " +
  //           platform) +
  //       "&sms=" +
  //       sms +
  //       "&addedby_id=" +
  //       addedby_id +
  //       "&addedby_name=" +
  //       addedby_name
  //   );
  //   fetch(reqURL, { cache: "no-store" })
  //     .then((res) => {
  //       res
  //         .json()
  //         .then((data) => {
  //           console.log(data);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const submitCreate = () => {
    const reqURL = encodeURI(
      AppConfig.apiUrlPrefix +
        "userDetails/store-user-details?phone=" +
        phone +
        "&status=" +
        status +
        "&selectedDate=" +
        selectedDate +
        "&account_username=" +
        account_username +
        "&name=" +
        name +
        "&dob=" +
        dob +
        "&alternate=" +
        alternate +
        "&email=" +
        email +
        "&masterId=" +
        masterId +
        "&depositBank=" +
        depositBank +
        "&language=" +
        language +
        "&category=" +
        category +
        "&player=" +
        player +
        "&player_platform=" +
        playerPlatform +
        "&city=" +
        city +
        "&pincode=" +
        pincode +
        "&bN1=" +
        bN1 +
        "&ifsc1=" +
        ifsc1 +
        "&aN1=" +
        aN1 +
        "&aHN1=" +
        aHN1 +
        "&bN2=" +
        bN2 +
        "&ifsc2=" +
        ifsc2 +
        "&aN2=" +
        aN2 +
        "&aHN2=" +
        aHN2 +
        "&message=" +
        message +
        "&addedby_id=" +
        addedby_id +
        "&addedby_name=" +
        addedby_name
    );
    setLoadingSnackbar(true);
    fetch(reqURL, { cache: "no-store" })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (data.status == "success") {
              setLoadingSnackbar(false);
              setSuccessSnackbar(true);
            } else if (data.status == "failure") {
              setErrorMsg(data.error);
              setLoadingSnackbar(false);
              setErrorSnackbar(true);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoadingSnackbar(false);
            setErrorSnackbar(true);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoadingSnackbar(false);
        setErrorSnackbar(true);
      });
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  return (
    <React.Fragment>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={3000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Storing user details...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={500}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Stored Successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="warning">
          {errorMsg}
        </Alert>
      </Snackbar>
      <Paper elevation={2} className={classes.paperStyle}>
        <Typography variant="h6" component="div" className={classes.text}>
          Status Details
        </Typography>
        <form id="status-form" className={classes.leadForm} method="GET">
          <TextField
            id="phone"
            name="phone"
            label="Phone Number"
            variant="outlined"
            type="number"
            size="small"
            required={true}
            className={classes.textField}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 10);
            }}
            min={0}
            value={phone}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
          />
          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
            required
          >
            <InputLabel htmlFor="status-select">Status</InputLabel>
            <Select
              native
              label="Status *"
              className={classes.textField}
              value={status}
              onChange={(event) => {
                setStatus(event.target.value);
              }}
            >
              <option value=""></option>
              {statusOptions.map((option, key) => (
                <option key={key} value={option.value}>
                  {option.title}
                </option>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="selectedDate"
            variant="outlined"
            size="small"
            type="datetime-local"
            className={classes.textField}
            onChange={(event) => {
              setSelectedDate(event.target.value);
            }}
          />
          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
          >
            <InputLabel id="language-select">Language</InputLabel>
            <Select
              native
              label="Language"
              className={classes.textField}
              value={language}
              onChange={(event) => {
                setLanguage(event.target.value);
              }}
            >
              <option value=""></option>
              {languageOptions.map((option) => (
                <option key={option.title} value={option.value}>
                  {option.title}
                </option>
              ))}
            </Select>
          </FormControl>
        </form>
      </Paper>
      <Paper elevation={2} className={classes.paperStyle}>
        <Typography variant="h6" component="div" className={classes.text}>
          User Details
        </Typography>
        <form id="user-form" method="GET">
          <div className={classes.leadForm}>
            <TextField
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              type="text"
              size="small"
              required={true}
              className={classes.textField}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <TextField
              id="alternate"
              name="alternate"
              label="Alternate No."
              variant="outlined"
              type="text"
              size="small"
              className={classes.textField}
              value={alternate}
              onChange={(event) => {
                setAlternatePhone(event.target.value);
              }}
            />
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              type="text"
              size="small"
              className={classes.textField}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <TextField
              id="dob"
              name="dob"
              label="Date of Birth"
              variant="outlined"
              type="text"
              size="small"
              placeholder="dd-mm-yyyy"
              className={classes.textField}
              value={dob}
              onChange={(event) => {
                setDob(event.target.value);
              }}
            />
          </div>
          <div className={classes.leadForm}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}
              required
            >
              <InputLabel id="category-select">Category</InputLabel>
              <Select
                native
                label="Category *"
                className={classes.textField}
                value={category}
                onChange={(event) => {
                  setCategory(event.target.value);
                }}
              >
                <option value=""></option>
                {categoryOptions.map((option) => (
                  <option key={option.title} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}
              required
            >
              <InputLabel id="player-select">Player Type</InputLabel>
              <Select
                native
                label="player Type *"
                className={classes.textField}
                value={player}
                onChange={(event) => {
                  setPlayer(event.target.value);
                }}
              >
                <option value=""></option>
                {playerOptions.map((option) => (
                  <option key={option.title} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}
              required
            >
              <InputLabel id="player-platform-select">
                Player Platform
              </InputLabel>
              <Select
                native
                label="player platform  *"
                className={classes.textField}
                value={playerPlatform}
                onChange={(event) => {
                  setPlayerPlatform(event.target.value);
                }}
              >
                <option value=""></option>
                {platformOptions.map((option) => (
                  <option key={option.title} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            {status == "Account created" ||
            status == "Active / Existing player" ? (
              <TextField
                id="account username"
                name="account username"
                label="Username"
                variant="outlined"
                type="text"
                size="small"
                // required={true}
                className={classes.textField}
                value={account_username}
                onChange={(event) => {
                  setAccountUsername(event.target.value);
                }}
              />
            ) : (
              <TextField
                id="account username"
                name="account username"
                label="Username"
                variant="outlined"
                type="text"
                size="small"
                // required={true}
                className={classes.textField}
                value={account_username}
                onChange={(event) => {
                  setAccountUsername(event.target.value);
                }}
                disabled
              />
            )}
          </div>
          <div className={classes.leadForm}>
            <TextField
              id="master id"
              name="master id"
              label="Master ID"
              variant="outlined"
              type="text"
              size="small"
              className={classes.textField}
              value={masterId}
              onChange={(event) => {
                setMasterId(event.target.value);
              }}
            />
            <TextField
              id="deposit bank name"
              name="deposit bank name"
              label="Deposit Bank Name"
              variant="outlined"
              type="text"
              size="small"
              className={classes.textField}
              value={depositBank}
              onChange={(event) => {
                setDepositBank(event.target.value);
              }}
            />
          </div>
        </form>
      </Paper>
      <Paper elevation={2} className={classes.paperStyle}>
        <Typography variant="h6" component="div" className={classes.text}>
          Address Details
        </Typography>
        <form id="address-form" className={classes.leadForm} method="GET">
          <TextField
            id="city"
            name="City"
            label="City"
            variant="outlined"
            type="text"
            size="small"
            className={classes.textField}
            value={city}
            onChange={(event) => {
              setCity(event.target.value);
            }}
          />
          <TextField
            id="pincode"
            name="pincode"
            label="Pincode"
            variant="outlined"
            type="text"
            size="small"
            className={classes.textField}
            value={pincode}
            onChange={(event) => {
              setPincode(event.target.value);
            }}
          />
        </form>
      </Paper>
      <Paper elevation={2} className={classes.paperStyle}>
        <Typography variant="h6" component="div" className={classes.text}>
          Bank Details
        </Typography>
        <Typography variant="h6" component="div" className={classes.subText}>
          Account Details - 1
        </Typography>
        <form id="bankf" className={classes.leadForm} method="GET">
          <TextField
            id="bank_name1"
            name="bank_name1"
            label="Bank Name"
            type="text"
            size="small"
            variant="outlined"
            value={bN1}
            className={classes.textField}
            onChange={(event) => {
              setBN1(event.target.value);
            }}
          />
          <TextField
            id="ifsc_code1"
            name="ifsc_code1"
            label="IFSC Code"
            type="text"
            size="small"
            variant="outlined"
            value={ifsc1}
            className={classes.textField}
            onChange={(event) => {
              setIfsc1(event.target.value);
            }}
          />
          <TextField
            id="account_holder_name1"
            name="account_holder_name1"
            label="Account Holder Name"
            type="text"
            size="small"
            variant="outlined"
            value={aHN1}
            className={classes.textField}
            onChange={(event) => {
              setAHN1(event.target.value);
            }}
          />
          <TextField
            id="account_number1"
            name="account_number1"
            label="Account Number"
            type="text"
            size="small"
            variant="outlined"
            value={aN1}
            className={classes.textField}
            onChange={(event) => {
              setAN1(event.target.value);
            }}
          />
        </form>
        <Typography variant="h6" component="div" className={classes.subText}>
          Account Details - 2
        </Typography>
        <form id="bankf2" className={classes.leadForm} method="GET">
          <TextField
            id="bank_name2"
            name="bank_name2"
            label="Bank Name"
            type="text"
            size="small"
            variant="outlined"
            value={bN2}
            className={classes.textField}
            onChange={(event) => {
              setBN2(event.target.value);
            }}
          />
          <TextField
            id="ifsc_code2"
            name="ifsc_code2"
            label="IFSC Code"
            type="text"
            size="small"
            variant="outlined"
            value={ifsc2}
            className={classes.textField}
            onChange={(event) => {
              setIfsc2(event.target.value);
            }}
          />
          <TextField
            id="account_holder_name2"
            name="account_holder_name2"
            label="Account Holder Name"
            type="text"
            size="small"
            variant="outlined"
            value={aHN2}
            className={classes.textField}
            onChange={(event) => {
              setAHN2(event.target.value);
            }}
          />
          <TextField
            id="account_number2"
            name="account_number2"
            label="Account Number"
            type="text"
            size="small"
            variant="outlined"
            value={aN2}
            className={classes.textField}
            onChange={(event) => {
              setAN2(event.target.value);
            }}
          />
        </form>
      </Paper>
      <Paper elevation={2} className={classes.paperStyle}>
        <Typography variant="h6" component="div" className={classes.text}>
          Others
        </Typography>
        <form id="cf" className={classes.leadForm} method="GET">
          <TextField
            id="message"
            name="message"
            label="Message"
            variant="outlined"
            type="text"
            size="small"
            multiline={true}
            rows={3}
            className={classes.textField}
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
          />
        </form>
      </Paper>
      {/* <Paper elevation={2} className={classes.paperStyle}>
        <Typography variant="h6" component="div" className={classes.text}>
          Send SMS
        </Typography>
        <form id="sms-form" method="GET">
          <div className={classes.leadForm}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <InputLabel htmlFor="games-select">Platform</InputLabel>
              <Select
                native
                label="Platform"
                className={classes.textField}
                onChange={(event) => {
                  setPlatform(event.target.value);
                }}
              >
                <option value=""></option>
                {game.map((data, key) => (
                  <option key={key} value={data.value}>
                    {data.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="game link"
              name="game link"
              label="Game Link"
              variant="outlined"
              type="text"
              size="small"
              className={classes.textField}
              value={platform}
            />
          </div>
          <TextField
            id="sms"
            name="sms"
            label="Custom SMS"
            variant="outlined"
            type="text"
            size="small"
            multiline={true}
            rows={3}
            className={classes.textField}
            value={customSMS}
            onChange={(event) => {
              setCustomSMS(event.target.value);
            }}
          />
          <div className={classes.checkboxRow}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ padding: "0rem 0.5rem 0rem 0rem" }}
                  checked={sms}
                  onChange={(event) => {
                    setSMS(!sms);
                  }}
                  color="primary"
                  value={sms}
                  name="sms"
                />
              }
              label={
                <span className={classes.grey}>
                  I would like to send custom SMS
                </span>
              }
            />
          </div>
        </form>
      </Paper> */}
      <div className={classes.buttons}>
        <Button
          variant="contained"
          title="Cancel"
          size="large"
          onClick={submitClear}
          rel="noopener"
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        {/* <Button
          variant="contained"
          title="Send link"
          size="large"
          onClick={submitSend}
          rel="noopener"
          className={classes.linkButton}
        >
          Send link
        </Button> */}
        <Button
          variant="contained"
          title="Create"
          size="large"
          onClick={submitCreate}
          rel="noopener"
          className={classes.createButton}
        >
          Create
        </Button>
      </div>
    </React.Fragment>
  );
};

export default LeadForm;
