/* Common Imports */

import React from "react";
import {
  TextField,
  CssBaseline,
  Paper,
  Grid,
  Typography,
  Container,
  Avatar,
  Button,
} from "@material-ui/core";
import { useCookies } from "react-cookie";
import { useIdleTimer } from "react-idle-timer";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../components/layout-components/Header";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AppConfig from "../AppConfig";

/* Component Imports */

import AgentLayout from "../components/layout-components/agent-layout/Layout";
import AdminLayout from "../components/layout-components/admin-layout/Layout";
import ManagerLayout from "../components/layout-components/manager-layout/Layout";
import LeadLayout from "../components/layout-components/lead-layout/Layout";
import FinanceAgentLayout from "../components/layout-components/finance-agent-layout/Layout";
import FinanceLeadLayout from "../components/layout-components/finance-lead-layout/Layout";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: "url(/images/illustrations/login/background.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh ",
  },
  image: {
    backgroundImage: "url(/images/illustrations/login/login.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: { color: "#BD0000" },
}));

const Index = ({ agents, financeAgents }) => {
  const classes = useStyles();
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  const [user_id, setUserID] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(
    "Enter User ID and Password"
  );
  const timeout = 3600000;
  // eslint-disable-next-line no-unused-vars
  const [remaining, setRemaining] = React.useState(timeout);

  const handleRemoveCookies = () => {
    removeCookie("user_id", { path: "/" });
    removeCookie("role", { path: "/" });
    removeCookie("username", { path: "/" });
    removeCookie("platform", { path: "/" });
    removeCookie("isLoggedIn", { path: "/" });
    window.location.href = "/";
  };

  const logout = async () => {
    await fetch(
      encodeURI(
        AppConfig.apiUrlPrefix +
          "logout-time?user_id=" +
          cookies.user_id +
          "&username=" +
          cookies.username +
          "&role=" +
          cookies.role
      ),
      {
        method: "POST",
        cache: "no-store",
      }
    );
    handleRemoveCookies();
  };

  const handleOnIdle = () => {
    if (cookies.isLoggedIn === "true") {
      logout();
      // alert("Your session has expired. Please login again.");
    }
  };

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  React.useEffect(() => {
    setRemaining(getRemainingTime());

    setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1000);
  }, []);

  const submitLogin = () => {
    const reqURL =
      AppConfig.apiUrlPrefix +
      "login?user_id=" +
      encodeURIComponent(user_id) +
      "&password=" +
      encodeURIComponent(password);
    fetch(reqURL, { cache: "no-store" })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (data.status == "success") {
              setCookie(
                "user_id",
                data.payload.user.user_id,
                { maxAge: 60 * 60 * 24 * 7 },
                { path: "/" }
              );
              setCookie(
                "role",
                data.payload.user.role,
                { maxAge: 60 * 60 * 24 * 7 },
                { path: "/" }
              );
              setCookie(
                "username",
                data.payload.user.username,
                { maxAge: 60 * 60 * 24 * 7 },
                {
                  path: "/",
                }
              );
              setCookie(
                "platform",
                data.payload.user.platform,
                { maxAge: 60 * 60 * 24 * 7 },
                {
                  path: "/",
                }
              );
              setCookie(
                "isLoggedIn",
                data.payload.user.isLoggedIn,
                { maxAge: 60 * 60 * 24 * 7 },
                {
                  path: "/",
                }
              );
              window.location.href = "/";
            } else if (data.status === "error") {
              setErrorMessage(data.error);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {typeof window !== "undefined" && (
        <div>
          <Header title="Aone Technology" />
          {cookies.isLoggedIn == undefined && (
            <div className={classes.root}>
              <Container>
                <Grid container component="main">
                  <CssBaseline />
                  <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    className={classes.image}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                  >
                    <div className={classes.paper}>
                      <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h5">
                        Sign in
                      </Typography>
                      <form className={classes.form}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="user_id"
                          label="User ID"
                          name="user id"
                          autoFocus
                          onChange={(event) => {
                            setUserID(event.target.value);
                          }}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}
                        />
                        <Button
                          onClick={submitLogin}
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          Sign In
                        </Button>
                      </form>
                      <Typography
                        variant="body2"
                        align="center"
                        component="span"
                        className={classes.error}
                      >
                        {errorMessage}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
          )}

          {cookies.isLoggedIn && cookies.role == "agent" && (
            <React.Fragment>
              <AgentLayout
                user_id={cookies.user_id}
                username={cookies.username}
                role={cookies.role}
                assignedto_platform={cookies.platform}
                agents={agents}
                financeAgents={financeAgents}
                handleRemoveCookies={handleRemoveCookies}
              />
            </React.Fragment>
          )}

          {cookies?.isLoggedIn && cookies.role == "admin" && (
            <React.Fragment>
              <AdminLayout
                user_id={cookies.user_id}
                username={cookies.username}
                role={cookies.role}
                agents={agents}
                financeAgents={financeAgents}
                handleRemoveCookies={handleRemoveCookies}
              />
            </React.Fragment>
          )}

          {cookies.isLoggedIn && cookies.role == "team lead" && (
            <React.Fragment>
              <LeadLayout
                user_id={cookies.user_id}
                username={cookies.username}
                role={cookies.role}
                assignedto_platform={cookies.platform}
                agents={agents}
                financeAgents={financeAgents}
                handleRemoveCookies={handleRemoveCookies}
              />
            </React.Fragment>
          )}

          {cookies.isLoggedIn && cookies.role == "manager" && (
            <React.Fragment>
              <ManagerLayout
                user_id={cookies.user_id}
                username={cookies.username}
                role={cookies.role}
                assignedto_platform={cookies.platform}
                agents={agents}
                financeAgents={financeAgents}
                handleRemoveCookies={handleRemoveCookies}
              />
            </React.Fragment>
          )}

          {cookies.isLoggedIn && cookies.role == "finance agent" && (
            <React.Fragment>
              <FinanceAgentLayout
                user_id={cookies.user_id}
                username={cookies.username}
                role={cookies.role}
                assignedto_platform={cookies.platform}
                financeAgents={financeAgents}
                handleRemoveCookies={handleRemoveCookies}
              />
            </React.Fragment>
          )}

          {cookies.isLoggedIn && cookies.role == "finance lead" && (
            <React.Fragment>
              <FinanceLeadLayout
                user_id={cookies.user_id}
                username={cookies.username}
                role={cookies.role}
                assignedto_platform={cookies.platform}
                handleRemoveCookies={handleRemoveCookies}
              />
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export async function getServerSideProps() {
  const res = await fetch(AppConfig.apiUrlPrefix + "agent-dashboard/agents", {
    cache: "no-store",
  });

  const res1 = await fetch(
    AppConfig.apiUrlPrefix + "agent-dashboard/finance-agents",
    {
      cache: "no-store",
    }
  );

  const agents = await res.json();
  const financeAgents = await res1.json();

  return { props: { agents, financeAgents } };
}

export default Index;
