/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Button } from "@material-ui/core";
import StatusCard from "../../cards/StatusCard";
import TicketTable from "./TicketTable";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.only("sm")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.only("md")]: {
      flexWrap: "wrap",
    },
    width: "100%",
    gap: "0.5rem",
    paddingBottom: "2rem",
  },
  total: {
    background: "#2AA9E1",
    textTransform: "none",
  },
  pending: {
    backgroundColor: "#EA7575",
    textTransform: "none",
  },
  closed: {
    backgroundColor: "#2AE194",
    textTransform: "none",
  },
  open: {
    background: "#EA9D75",
    textTransform: "none",
  },
  invalid: {
    background: "#808080",
    textTransform: "none",
  },
}));

const Tickets = (props) => {
  const classes = useStyles();

  const [totalTickets, setTotalTickets] = React.useState(0);
  const [pendingTickets, setPendingTickets] = React.useState(0);
  const [invalidTickets, setInvalidTickets] = React.useState(0);
  const [closedTickets, setClosedTickets] = React.useState(0);
  const [openTickets, setOpenTickets] = React.useState(0);
  const [pending, setPending] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [closed, setClosed] = React.useState(false);
  const [total, setTotal] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);

  return (
    <React.Fragment>
      <div className={classes.flex}>
        <Button
          onClick={(event) => {
            setTotal(true);
            setOpen(false);
            setPending(false);
            setInvalid(false);
            setClosed(false);
            setValue(1);
          }}
        >
          <StatusCard
            title="Total Tickets"
            subtitle={totalTickets}
            icon="/images/icons/ticket/ticket.svg"
            cardClass={classes.total}
          />
        </Button>
        <Button
          onClick={(event) => {
            setOpen(true);
            setPending(false);
            setClosed(false);
            setInvalid(false);
            setTotal(false);
            setValue(1);
          }}
        >
          <StatusCard
            title="Open Tickets"
            subtitle={openTickets}
            icon="/images/icons/ticket/ticket.svg"
            cardClass={classes.open}
          />
        </Button>
        <Button
          onClick={(event) => {
            setPending(true);
            setOpen(false);
            setClosed(false);
            setInvalid(false);
            setTotal(false);
            setValue(1);
          }}
        >
          <StatusCard
            title="Pending Tickets"
            subtitle={pendingTickets}
            icon="/images/icons/ticket/ticket.svg"
            cardClass={classes.pending}
          />
        </Button>
        <Button
          onClick={(event) => {
            setInvalid(true);
            setPending(false);
            setOpen(false);
            setClosed(false);
            setTotal(false);
            setValue(1);
          }}
        >
          <StatusCard
            title="Invalid Tickets"
            subtitle={invalidTickets}
            icon="/images/icons/ticket/ticket.svg"
            cardClass={classes.invalid}
          />
        </Button>
        <Button
          onClick={(event) => {
            setClosed(true);
            setOpen(false);
            setPending(false);
            setTotal(false);
            setInvalid(false);
            setValue(1);
          }}
        >
          <StatusCard
            title="Closed Tickets"
            subtitle={closedTickets}
            icon="/images/icons/ticket/ticket.svg"
            cardClass={classes.closed}
          />
        </Button>
      </div>
      <TicketTable
        user_id={props.user_id}
        username={props.username}
        role={props.role}
        assignedto_platform={props.assignedto_platform}
        pending={pending}
        open={open}
        closed={closed}
        total={total}
        invalid={invalid}
        onTicketChange={() => {
          setPending(false);
          setOpen(false);
          setClosed(false);
          setTotal(false);
          setInvalid(false);
        }}
        onTicketNumbersChange={(
          totalTickets,
          pendingTickets,
          invalidTickets,
          closedTickets,
          openTickets
        ) => {
          setTotalTickets(totalTickets);
          setPendingTickets(pendingTickets);
          setInvalidTickets(invalidTickets);
          setClosedTickets(closedTickets);
          setOpenTickets(openTickets);
        }}
      />
    </React.Fragment>
  );
};

export default Tickets;
