import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia, CardContent, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    padding: "1rem",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: "0.375rem",
    width: "100%",
  },
  media: {
    margin: "0.5rem 0rem 0rem 0rem",
    backgroundSize: "auto",
  },
  infotitle: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    color: "#ffffff",
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "1.875rem",
  },
  infotext: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    color: "#ffffff",
    fontWeight: "400",
    fontSize: "1.5rem",
    lineHeight: "1rem",
    marginTop: "1rem",
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const StatusCard = (props) => {
  const classes = useStyles();
  return (
    <Card
      className={clsx(
        classes.root,
        props.cardClass ? props.cardClass : classes.cardClass
      )}
    >
      <CardContent className={classes.content}>
        <Typography
          variant="h6"
          component="h6"
          align="center"
          className={classes.infotitle}
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          align="center"
          className={classes.infotext}
        >
          {props.subtitle ? props.subtitle : 0}
        </Typography>
      </CardContent>
      <CardMedia className={classes.media}>
        <img
          decode="async"
          alt="status"
          longdesc="https://aonetechnology.in/"
          loading="eager"
          referrerPolicy="no-referrer"
          height={60}
          width={60}
          src={props.icon ? props.icon : "/images/icons/ticket/ticket.svg"}
        />
      </CardMedia>
    </Card>
  );
};

export default StatusCard;
