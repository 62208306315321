/* Common Imports */

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
  Snackbar,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MuiIconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import statusOptions from "../../Leads/data/statusOptions";
import categoryOptions from "../../Leads/data/categoryOptions";
import languageOptions from "../../Leads/data/languageOptions";
import playerOptions from "../../Leads/data/leadPlayerOptions";
import platformOptions from "../../Leads/data/platformOptions";
import CreateTicket from "./CreateTicket";
import SimpleReactLightbox from "simple-react-lightbox";

/* Data Imports */

import AppConfig from "../../../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "1.2rem",
    fontWeight: "600",
    color: "#0D4972",
    paddingBottom: "1rem",
  },
  subText: {
    fontSize: "0.8rem",
    fontWeight: "600",
    color: "#0D4972",
    paddingBottom: "1rem",
  },
  modal: {
    padding: "1rem",
    width: "100%",
  },
  heading: {
    width: "100%",
    color: "#0D4972",
    fontWeight: "400",
    fontSize: "2rem",
    lineHeight: "3rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "2rem",
  },
  textFieldHalf: {
    width: "100%",
    margin: "0rem 0rem 2rem 0rem",
    [theme.breakpoints.up("md")]: {
      width: "48%",
    },
  },
  row: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cancelButton: {
    color: "#FFFFFF",
    background: "#727272",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#727272",
    },
  },
  deleteButton: {
    color: "#FFFFFF",
    background: "#2AA9E1",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#2AA9E1",
    },
  },
  ticketButton: {
    color: "#FFFFFF",
    background: "#EA9D75",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#EA9D75",
    },
  },
  updateButton: {
    color: "#FFFFFF",
    background: "#0D4972",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#0D4972",
    },
  },
  formControl: { width: "100%" },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UpdateUserDetails = (props) => {
  const classes = useStyles();
  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [objectID, setObjectID] = React.useState("");
  const [userID, setUserID] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [account_username, setAccountUsername] = React.useState("");
  const [name, setName] = React.useState("");
  const [alternate, setAlternatePhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [masterId, setMasterId] = React.useState("");
  const [depositBank, setDepositBank] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [player, setPlayer] = React.useState("");
  const [playerPlatform, setPlayerPlatform] = React.useState("");
  const [city, setCity] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState("");
  // const [addedby, setAddedBy] = React.useState("");
  const [bN1, setBN1] = React.useState("");
  const [ifsc1, setIfsc1] = React.useState("");
  const [aN1, setAN1] = React.useState("");
  const [aHN1, setAHN1] = React.useState("");
  const [bN2, setBN2] = React.useState("");
  const [ifsc2, setIfsc2] = React.useState("");
  const [aN2, setAN2] = React.useState("");
  const [aHN2, setAHN2] = React.useState("");
  const [openTicketModal, setOpenTicketModal] = React.useState(false);

  React.useEffect(() => {
    if (props.updateData !== undefined) {
      setUserID(props.updateData[0] ? props.updateData[0] : "");
      setPhone(props.updateData[4] ? props.updateData[4] : "");
      setName(props.updateData[1] ? props.updateData[1] : "");
      setAccountUsername(props.updateData[14] ? props.updateData[14] : "");
      setAlternatePhone(props.updateData[5] ? props.updateData[5] : "");
      setEmail(props.updateData[3] ? props.updateData[3] : "");
      setDob(props.updateData[2] ? props.updateData[2] : "");
      setMasterId(props.updateData[15] ? props.updateData[15] : "");
      setDepositBank(props.updateData[16] ? props.updateData[16] : "");
      setLanguage(props.updateData[7] ? props.updateData[7] : "");
      setCategory(props.updateData[8] ? props.updateData[8] : "");
      setPlayer(props.updateData[9] ? props.updateData[9] : "");
      setPlayerPlatform(props.updateData[10] ? props.updateData[10] : "");
      setCity(props.updateData[12] ? props.updateData[12] : "");
      setPincode(props.updateData[13] ? props.updateData[13] : "");
      setMessage(props.updateData[17] ? props.updateData[17] : "");
      setStatus(props.updateData[11] ? props.updateData[11] : "");
      setBN1(props.updateData[21] ? props.updateData[21] : "");
      setIfsc1(props.updateData[22] ? props.updateData[22] : "");
      setAN1(props.updateData[24] ? props.updateData[24] : "");
      setAHN1(props.updateData[23] ? props.updateData[23] : "");
      setBN2(props.updateData[25] ? props.updateData[25] : "");
      setIfsc2(props.updateData[26] ? props.updateData[26] : "");
      setAN2(props.updateData[28] ? props.updateData[28] : "");
      setAHN2(props.updateData[27] ? props.updateData[27] : "");
      // setAddedBy(props.updateData[27] ? props.updateData[27] : "");
      setObjectID(props.updateData[30] ? props.updateData[30] : "");
    }
  }, [props.updateData]);

  const submitUpdate = () => {
    const reqURL = encodeURI(
      AppConfig.apiUrlPrefix +
        "userDetails/update-user-details?phone=" +
        phone +
        "&status=" +
        status +
        "&selectedDate=" +
        selectedDate +
        "&account_username=" +
        account_username +
        "&name=" +
        name +
        "&dob=" +
        dob +
        "&alternate=" +
        alternate +
        "&email=" +
        email +
        "&masterId=" +
        masterId +
        "&depositBank=" +
        depositBank +
        "&language=" +
        language +
        "&category=" +
        category +
        "&player=" +
        player +
        "&player_platform=" +
        playerPlatform +
        "&city=" +
        city +
        "&pincode=" +
        pincode +
        "&bN1=" +
        bN1 +
        "&ifsc1=" +
        ifsc1 +
        "&aN1=" +
        aN1 +
        "&aHN1=" +
        aHN1 +
        "&bN2=" +
        bN2 +
        "&ifsc2=" +
        ifsc2 +
        "&aN2=" +
        aN2 +
        "&aHN2=" +
        aHN2 +
        "&message=" +
        message +
        "&updatedby_id=" +
        props.assignedby_id +
        "&updatedby_name=" +
        props.assignedby_name +
        "&user_id=" +
        userID +
        "&_id=" +
        objectID
    );
    setLoadingSnackbar(true);
    fetch(reqURL, { cache: "no-store" })
      .then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            setLoadingSnackbar(false);
            setSuccessSnackbar(true);
            typeof props.onUpdate === "function"
              ? props.onUpdate(data)
              : undefined;
          })
          .catch((error) => {
            console.log(error);
            setLoadingSnackbar(false);
            setErrorSnackbar(true);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoadingSnackbar(false);
        setErrorSnackbar(true);
      });
  };

  const onDelete = (props) => {
    const reqURL = encodeURI(
      AppConfig.apiUrlPrefix + "userDetails/delete-user-details?_id=" + objectID
    );
    fetch(reqURL, { cache: "no-store" })
      .then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            typeof props.onDelete === "function"
              ? props.onDelete(data)
              : undefined;
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  const handleCloseTicket = () => setOpenTicketModal(false);

  return (
    <React.Fragment>
      <SimpleReactLightbox>
        <CreateTicket
          open={openTicketModal}
          onClose={handleCloseTicket}
          assignedby_id={props.assignedby_id}
          assignedby_name={props.assignedby_name}
          assignedto_platform={props.assignedto_platform}
          financeLeads={props.financeLeads}
          user_id={userID}
          username={account_username}
          phone={phone}
          masterId={masterId}
          depositBank={depositBank}
          language={language}
          playerPlatform={playerPlatform}
          priority={category}
          bN1={bN1}
          ifsc1={ifsc1}
          aHN1={aHN1}
          aN1={aN1}
          bN2={bN2}
          ifsc2={ifsc2}
          aHN2={aHN2}
          aN2={aN2}
          onAdd={(data) => {
            setOpenTicketModal(false);
          }}
        />
      </SimpleReactLightbox>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={3000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Updating User Details...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={500}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Successfully Updated.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          Oops! Something seems to have gone wrong!
        </Alert>
      </Snackbar>
      <Dialog
        className={classes.modal}
        onClose={props.onClose}
        aria-labelledby="update-user-details-modal"
        open={props.open}
        maxWidth="lg"
      >
        <DialogTitle
          id="update-user-details-modal-title"
          onClose={props.onClose}
        >
          <Typography variant="h5" component="p" className={classes.heading}>
            Update User Details
          </Typography>
          <MuiIconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.onClose}
          >
            <CloseIcon />
          </MuiIconButton>
        </DialogTitle>
        <form method="GET" id="udf">
          <DialogContent dividers>
            <div className={classes.row}>
              <TextField
                id="name"
                name="name"
                label="Name"
                type="text"
                size="small"
                className={classes.textFieldHalf}
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
              <TextField
                id="phone"
                name="phone"
                label="Phone Number"
                type="number"
                size="small"
                className={classes.textFieldHalf}
                value={phone}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                min={0}
                onChange={(event) => {
                  setPhone(event.target.value);
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="alternate"
                name="alternate"
                label="Alternate No."
                type="text"
                size="small"
                className={classes.textFieldHalf}
                value={alternate}
                onChange={(event) => {
                  setAlternatePhone(event.target.value);
                }}
              />
              <TextField
                id="email"
                name="email"
                label="Email"
                type="text"
                size="small"
                className={classes.textFieldHalf}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="dob"
                name="dob"
                label="Date of Birth"
                type="text"
                size="small"
                placeholder="dd-mm-yyyy"
                className={classes.textFieldHalf}
                value={dob}
                onChange={(event) => {
                  setDob(event.target.value);
                }}
              />
              <TextField
                id="account username"
                name="account username"
                label="Username"
                type="text"
                size="small"
                className={classes.textFieldHalf}
                value={account_username}
                onChange={(event) => {
                  setAccountUsername(event.target.value);
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="master id"
                name="master id"
                label="Master ID"
                type="text"
                size="small"
                className={classes.textFieldHalf}
                value={masterId}
                onChange={(event) => {
                  setMasterId(event.target.value);
                }}
              />
              <TextField
                id="deposit bank name"
                name="deposit bank name"
                label="Deposit Bank Name"
                type="text"
                size="small"
                className={classes.textFieldHalf}
                value={depositBank}
                onChange={(event) => {
                  setDepositBank(event.target.value);
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="city"
                name="City"
                label="City"
                type="text"
                size="small"
                className={classes.textFieldHalf}
                value={city}
                onChange={(event) => {
                  setCity(event.target.value);
                }}
              />
              <TextField
                id="pincode"
                name="pincode"
                label="Pincode"
                type="text"
                size="small"
                className={classes.textFieldHalf}
                value={pincode}
                onChange={(event) => {
                  setPincode(event.target.value);
                }}
              />
            </div>

            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="language-select">Language</InputLabel>
              <Select
                native
                label="Language"
                className={classes.textField}
                value={language}
                onChange={(event) => {
                  setLanguage(event.target.value);
                }}
              >
                <option value=""></option>
                {languageOptions.map((option) => (
                  <option key={option.title} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel htmlFor="status-select">Status</InputLabel>
              <Select
                native
                label="Status"
                className={classes.textField}
                value={status}
                onChange={(event) => {
                  setStatus(event.target.value);
                }}
              >
                <option value=""></option>
                {statusOptions.map((option, key) => (
                  <option key={key} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="selectedDate"
              size="small"
              type="datetime-local"
              className={classes.textField}
              value={selectedDate}
              onChange={(event) => {
                setSelectedDate(event.target.value);
              }}
            />
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="category-select">Category</InputLabel>
              <Select
                native
                label="Category"
                className={classes.textField}
                value={category}
                onChange={(event) => {
                  setCategory(event.target.value);
                }}
              >
                <option value=""></option>
                {categoryOptions.map((option) => (
                  <option key={option.title} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="player-select">Player Category</InputLabel>
              <Select
                native
                label="player category"
                className={classes.textField}
                value={player}
                onChange={(event) => {
                  setPlayer(event.target.value);
                }}
              >
                <option value=""></option>
                {playerOptions.map((option) => (
                  <option key={option.title} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="player-platform-select">
                Player Platform
              </InputLabel>
              <Select
                native
                label="player platform"
                className={classes.textField}
                value={playerPlatform}
                onChange={(event) => {
                  setPlayerPlatform(event.target.value);
                }}
              >
                <option value=""></option>
                {platformOptions.map((option) => (
                  <option key={option.title} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Typography variant="h6" component="div" className={classes.text}>
              Bank Details
            </Typography>
            <Typography
              variant="h6"
              component="div"
              className={classes.subText}
            >
              Account Details - 1
            </Typography>
            <div className={classes.row}>
              <TextField
                id="bank_name1"
                name="bank_name1"
                label="Bank Name"
                type="text"
                size="small"
                value={bN1}
                className={classes.textFieldHalf}
                onChange={(event) => {
                  setBN1(event.target.value);
                }}
              />
              <TextField
                id="ifsc_code1"
                name="ifsc_code1"
                label="IFSC Code"
                type="text"
                size="small"
                value={ifsc1}
                className={classes.textFieldHalf}
                onChange={(event) => {
                  setIfsc1(event.target.value);
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="account_holder_name1"
                name="account_holder_name1"
                label="Account Holder Name"
                type="text"
                size="small"
                value={aHN1}
                className={classes.textFieldHalf}
                onChange={(event) => {
                  setAHN1(event.target.value);
                }}
              />
              <TextField
                id="account_number1"
                name="account_number1"
                label="Account Number"
                type="text"
                size="small"
                value={aN1}
                className={classes.textFieldHalf}
                onChange={(event) => {
                  setAN1(event.target.value);
                }}
              />
            </div>
            <Typography
              variant="h6"
              component="div"
              className={classes.subText}
            >
              Account Details - 2
            </Typography>
            <div className={classes.row}>
              <TextField
                id="bank_name2"
                name="bank_name2"
                label="Bank Name"
                type="text"
                size="small"
                value={bN2}
                className={classes.textFieldHalf}
                onChange={(event) => {
                  setBN2(event.target.value);
                }}
              />
              <TextField
                id="ifsc_code2"
                name="ifsc_code2"
                label="IFSC Code"
                type="text"
                size="small"
                value={ifsc2}
                className={classes.textFieldHalf}
                onChange={(event) => {
                  setIfsc2(event.target.value);
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="account_holder_name2"
                name="account_holder_name2"
                label="Account Holder Name"
                type="text"
                size="small"
                value={aHN2}
                className={classes.textFieldHalf}
                onChange={(event) => {
                  setAHN2(event.target.value);
                }}
              />
              <TextField
                id="account_number2"
                name="account_number2"
                label="Account Number"
                type="text"
                size="small"
                value={aN2}
                className={classes.textFieldHalf}
                onChange={(event) => {
                  setAN2(event.target.value);
                }}
              />
            </div>

            <TextField
              id="message"
              name="message"
              label="Message"
              type="text"
              size="small"
              multiline
              minRows={3}
              className={classes.textField}
              value={message}
              onChange={(event) => {
                setMessage(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              label="Close"
              onClick={props.onClose}
              className={classes.cancelButton}
            >
              Close
            </Button>
            {props.role == "admin" ? (
              <Button
                variant="contained"
                label="Delete"
                onClick={onDelete}
                className={classes.deleteButton}
              >
                Delete
              </Button>
            ) : null}
            {props.role == "agent" ? null : (
              <Button
                variant="contained"
                label="Create Ticket"
                onClick={(e) => {
                  setOpenTicketModal(true);
                }}
                className={classes.ticketButton}
              >
                Create Ticket
              </Button>
            )}
            <Button
              variant="contained"
              title="Submit"
              onClick={submitUpdate}
              rel="noopener"
              className={classes.updateButton}
              // disabled={
              //   props.assignedby_id == addedby ||
              //   props.role == "admin" ||
              //   props.updateData[16] == "Admin"
              //     ? false
              //     : true
              // }
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default UpdateUserDetails;
