import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Stepper, Step, StepButton, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  text: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textColor: {
    color: "#0D4972",
    fontWeight: 600,
  },
}));

const UpdatesStep = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {props.updatesData.map((label, index) => {
          return (
            <Step key={index}>
              <StepButton onClick={handleStep(index)}>
                <Typography
                  variant="body2"
                  component="span"
                  className={classes.text}
                >
                  {label.updatedby_name ? label.updatedby_name : ""} &nbsp;
                  {label.log_type ? label.log_type : ""} &nbsp;
                  {label.username !== undefined && label.username !== null ? (
                    <Typography variant="body2" component="span">
                      <span className={classes.textColor}>Username : </span>
                      &quot;{label.username}&quot;
                    </Typography>
                  ) : null}
                  &nbsp;
                  {label.phone !== undefined && label.phone !== null ? (
                    <Typography variant="body2" component="span">
                      <span className={classes.textColor}>Phone : </span>
                      &quot;{label.phone}&quot;
                    </Typography>
                  ) : null}
                  &nbsp;
                  {label.language !== undefined && label.language !== null ? (
                    <Typography variant="body2" component="span">
                      <span className={classes.textColor}>Language : </span>
                      &quot;{label.language}&quot;
                    </Typography>
                  ) : null}
                  &nbsp;
                  {label.category !== undefined && label.category !== null ? (
                    <Typography variant="body2" component="span">
                      <span className={classes.textColor}>Category : </span>
                      &quot;{label.category}&quot;
                    </Typography>
                  ) : null}
                  &nbsp;
                  {label.status !== undefined && label.status !== null ? (
                    <Typography variant="body2" component="span">
                      <span className={classes.textColor}>Status : </span>
                      &quot;{label.status}&quot;
                    </Typography>
                  ) : null}
                  &nbsp;
                  {label.priority !== undefined &&
                  label.priority !== null &&
                  label.priority !== "" ? (
                    <Typography variant="body2" component="span">
                      <span className={classes.textColor}>Priority : </span>
                      &quot;{label.priority}&quot;
                    </Typography>
                  ) : null}
                  &nbsp;
                  {label.bank[0] &&
                  label.bank[0].amount !== "" &&
                  label.bank[0].amount !== "-" &&
                  label.bank[0].amount !== null &&
                  label.bank[0].amount !== undefined ? (
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.text}
                    >
                      <span className={classes.textColor}>Amount : </span>&nbsp;
                      {label.bank[0] ? label.bank[0].amount : ""}
                    </Typography>
                  ) : null}
                  &nbsp;
                  {/* {label.bank[0] &&
                  label.bank[0].utr_number !== "" &&
                  label.bank[0].utr_number !== "-" &&
                  label.bank[0].utr_number !== null &&
                  label.bank[0].utr_number !== undefined ? (
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.text}
                    >
                      <span className={classes.textColor}>UTR Number : </span>
                      &nbsp;
                      {label.bank[0] ? label.bank[0].utr_number : ""}
                    </Typography>
                  ) : null}
                  &nbsp;
                  {label.bank[0] &&
                  label.bank[0].bank_name !== "" &&
                  label.bank[0].bank_name !== "-" &&
                  label.bank[0].bank_name !== null &&
                  label.bank[0].bank_name !== undefined ? (
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.text}
                    >
                      <span className={classes.textColor}>Bank Name : </span>
                      &nbsp;
                      {label.bank[0] ? label.bank[0].bank_name : ""}
                    </Typography>
                  ) : null}
                  &nbsp;
                  {label.bank[0] &&
                  label.bank[0].ifsc_code !== "" &&
                  label.bank[0].ifsc_code !== "-" &&
                  label.bank[0].ifsc_code !== null &&
                  label.bank[0].ifsc_code !== undefined ? (
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.text}
                    >
                      <span className={classes.textColor}>IFSC Code : </span>
                      &nbsp;
                      {label.bank[0] ? label.bank[0].ifsc_code : ""}
                    </Typography>
                  ) : null}
                  &nbsp;
                  {label.bank[0] &&
                  label.bank[0].account_holder_name !== "" &&
                  label.bank[0].account_holder_name !== "-" &&
                  label.bank[0].account_holder_name !== null &&
                  label.bank[0].account_holder_name !== undefined ? (
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.text}
                    >
                      <span className={classes.textColor}>
                        Account Holder Name :
                      </span>
                      &nbsp;
                      {label.bank[0] ? label.bank[0].account_holder_name : ""}
                    </Typography>
                  ) : null}
                  &nbsp;
                  {label.bank[0] &&
                  label.bank[0].account_number !== "" &&
                  label.bank[0].account_number !== "-" &&
                  label.bank[0].account_number !== null &&
                  label.bank[0].account_number !== undefined ? (
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.text}
                    >
                      <span className={classes.textColor}>
                        Account Number :
                      </span>
                      &nbsp;
                      {label.bank[0] ? label.bank[0].account_number : ""}
                    </Typography>
                  ) : null}
                  &nbsp; */}
                  {label.description !== undefined &&
                  label.description !== null &&
                  label.description !== "" ? (
                    <Typography variant="body2" component="span">
                      <span className={classes.textColor}>Description : </span>
                      &quot;{label.description}&quot;
                    </Typography>
                  ) : null}
                </Typography>
                {label.comment !== null &&
                label.comment !== undefined &&
                label.comment !== "" ? (
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.text}
                  >
                    <span className={classes.textColor}>Comment : </span>&nbsp;
                    {label.comment ? label.comment : ""}
                  </Typography>
                ) : null}
                <Typography
                  variant="body2"
                  component="span"
                  className={classes.text}
                >
                  {label.readable_updated_at ? label.readable_updated_at : ""}
                </Typography>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default UpdatesStep;
