import React from "react";
import MUIDataTable from "mui-datatables";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import {
  IconButton,
  Button,
  CircularProgress,
  Snackbar,
  Checkbox,
  FormControlLabel,
  Menu,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
// import AddIcon from "@material-ui/icons/Add";
// import AddTicketModal from "./AddTicketModal";
import AppConfig from "../../../../../AppConfig";
import categoryOptions from "../../../agent/tickets/data/categoryOptions";
import statusOptions from "../../../agent/tickets/data/statusOptions";
import priorityOptions from "../../../agent/tickets/data/priorityOptions";
import platformOptions from "../../../agent/Leads/data/platformOptions";
import UpdateTicketDetails from "../../../agent/tickets/sub-components/UpdateTicketDetails";
import TicketDetailsModal from "../../../agent/tickets/sub-components/TicketDetailsModal";
import SimpleReactLightbox from "simple-react-lightbox";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  p1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EA7575",
    padding: "0.5rem",
    color: "#ffffff",
    width: "80%",
    borderRadius: "0.3rem",
  },
  p2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EA9D75",
    padding: "0.5rem",
    color: "#ffffff",
    width: "80%",
    borderRadius: "0.3rem",
  },
  p3: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#75AFEA",
    padding: "0.5rem",
    color: "#ffffff",
    width: "80%",
    borderRadius: "0.3rem",
  },
  idButton: {
    color: "#727272",
    fontWeight: "400",
    fontSize: "1rem",
  },
  searchMenu: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "0.5rem 1rem 0.5rem 1rem",
  },
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "#FAFAFA !important",
        },
      },
      MuiButton: {
        root: { color: "#4A4A4A", fontWeight: "600" },
      },
      MuiTableSortLabel: {
        root: {
          "&$active": {
            color: "#0D4972",
            "&& $icon": {
              color: "#0D4972",
            },
          },
        },
      },
      MUIDataTableBodyCell: {
        root: {
          color: "#727272",
          fontSize: "1rem",
        },
      },
      MuiPaper: { root: { color: "#0D4972" } },
      MUIDataTableToolbar: {
        root: {
          backgroundColor: "#EDEDED",
        },
      },
    },
  });

const TicketTable = (props) => {
  const classes = useStyles();

  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(1);
  const [ticketData, setTicketData] = React.useState([]);
  // const [openAddModal, setOpenAddModal] = React.useState(false);
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [openDataModal, setOpenDataModal] = React.useState(false);
  const [rowD, setRowD] = React.useState([]);
  const [updateD, setUpdateD] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [searchText, setSearchText] = React.useState("");
  const [playerPlatform, setPlayerPlatform] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [priority, setPriority] = React.useState([]);
  const [ticketID, setTicketID] = React.useState(false);
  const [userID, setUserID] = React.useState(false);
  const [username, setUsername] = React.useState(false);
  const [phone, setPhone] = React.useState(false);
  const [masterId, setMasterId] = React.useState(false);
  const [bankname, setBankName] = React.useState(false);
  const [ifsc, setIfsc] = React.useState(false);
  const [accountnumber, setAccountNumber] = React.useState(false);
  const [acountholder, setAccountHolder] = React.useState(false);
  const [utr, setUtr] = React.useState(false);
  // const [amount, setAmount] = React.useState(false);
  const [description, setDescription] = React.useState(false);
  const [created, setCreated] = React.useState(false);
  const [assignedTo, setAssignedTo] = React.useState([]);
  const [assignedBy, setAssignedBy] = React.useState([]);
  const [closedBy, setClosedBy] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [refreshToken, setRefreshToken] = React.useState(Math.random());

  // const handleOpen = () => setOpenAddModal(true);
  // const handleClose = () => setOpenAddModal(false);
  const handleCloseUpdate = () => setOpenUpdateModal(false);

  const handleCloseData = () => setOpenDataModal(false);

  React.useEffect(() => {
    const fetchData = async () => {
      await fetch(
        AppConfig.apiUrlPrefix +
          "ticket-data?page=" +
          currentPage +
          "&limit=" +
          pageSize +
          "&sortOrder=" +
          (status == "Open" ? "asc" : "") +
          "&searchText=" +
          searchText +
          "&ticketID=" +
          ticketID +
          "&userID=" +
          userID +
          "&username=" +
          username +
          "&phone=" +
          phone +
          "&masterId=" +
          masterId +
          "&bankname=" +
          bankname +
          "&ifsc=" +
          ifsc +
          "&accountnumber=" +
          accountnumber +
          "&acountholder=" +
          acountholder +
          "&utr=" +
          utr +
          // "&amount=" +
          // amount +
          "&description=" +
          description +
          "&created=" +
          created,
        {
          cache: "no-store",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            category: category,
            status: status,
            priority: priority,
            playerPlatform: playerPlatform,
            assignedTo: assignedTo,
            assignedBy: assignedBy,
            closedBy: closedBy,
          }),
        }
      )
        .then((r) => r.json())
        .then((d) => {
          setTicketData(d.ticketsData);
          setTotalCount(d.count);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    };
    // const timer = setTimeout(() => setRefreshToken(Math.random()), 15000);
    fetchData();
    // return () => clearTimeout(timer);
  }, [
    currentPage,
    pageSize,
    searchText,
    category,
    status,
    priority,
    playerPlatform,
    ticketID,
    userID,
    username,
    phone,
    masterId,
    bankname,
    ifsc,
    accountnumber,
    acountholder,
    utr,
    created,
    assignedTo,
    assignedBy,
    closedBy,
    // amount,
    description,
    // refreshToken,
  ]);

  const ticketDataRefresh = async () => {
    await fetch(
      AppConfig.apiUrlPrefix +
        "ticket-data?page=" +
        currentPage +
        "&limit=" +
        pageSize +
        "&sortOrder=" +
        (status == "Open" ? "asc" : "") +
        "&searchText=" +
        searchText +
        "&ticketID=" +
        ticketID +
        "&userID=" +
        userID +
        "&username=" +
        username +
        "&phone=" +
        phone +
        "&masterId=" +
        masterId +
        "&bankname=" +
        bankname +
        "&ifsc=" +
        ifsc +
        "&accountnumber=" +
        accountnumber +
        "&acountholder=" +
        acountholder +
        "&utr=" +
        utr +
        // "&amount=" +
        // amount +
        "&description=" +
        description +
        "&created=" +
        created,
      {
        cache: "no-store",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          category: category,
          status: status,
          priority: priority,
          playerPlatform: playerPlatform,
          assignedTo: assignedTo,
          assignedBy: assignedBy,
          closedBy: closedBy,
        }),
      }
    )
      .then((r) => r.json())
      .then((d) => {
        setTicketData(d.ticketsData);
        setTotalCount(d.count);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  const ticketDownload = async () => {
    const reqURL =
      AppConfig.apiUrlPrefix +
      "downloads/ticket-data?searchText=" +
      searchText +
      "&sortOrder=" +
      (status == "Open" ? "asc" : "") +
      "&ticketID=" +
      ticketID +
      "&userID=" +
      userID +
      "&username=" +
      username +
      "&phone=" +
      phone +
      "&masterId=" +
      masterId +
      "&bankname=" +
      bankname +
      "&ifsc=" +
      ifsc +
      "&accountnumber=" +
      accountnumber +
      "&acountholder=" +
      acountholder +
      "&utr=" +
      utr +
      // "&amount=" +
      // amount +
      "&description=" +
      description +
      "&created=" +
      created;
    setLoadingSnackbar(true);
    const res = await fetch(reqURL, {
      cache: "no-store",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        category: category,
        status: status,
        priority: priority,
        playerPlatform: playerPlatform,
        assignedTo: assignedTo,
        assignedBy: assignedBy,
        closedBy: closedBy,
      }),
    });
    const response = await res.json();
    if (res.status === 200) {
      setLoadingSnackbar(false);
      setSuccessSnackbar(true);
      const link = document.createElement("a");
      link.href = response.objectLink;
      link.download = "tickets.csv";
      link.dispatchEvent(new MouseEvent("click"));
    } else {
      setLoadingSnackbar(false);
      setErrorSnackbar(true);
    }
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  const columns = [
    {
      name: "Ticket ID",
      options: {
        filter: false,
        customBodyRender: (ticket_id, values) => {
          return (
            <Button
              aria-label="Info"
              className={classes.idButton}
              onClick={(e) => {
                setRowD(values.rowData);
                setOpenDataModal(true);
              }}
            >
              {ticket_id}
            </Button>
          );
        },
      },
    },
    {
      name: "User ID",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Username",
      options: {
        filter: false,
      },
    },
    {
      name: "Mobile Number",
      options: {
        filter: false,
      },
    },
    {
      name: "Language",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Player Platform",
      options: {
        filterOptions: {
          names: platformOptions.map((platform) => platform.value),
        },
      },
    },
    {
      name: "Master ID",
      options: {
        filter: false,
      },
    },
    {
      name: "Category",
      options: {
        filterOptions: {
          names: categoryOptions.map((category) => category.value),
        },
      },
    },
    {
      name: "Deposit Bank Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Priority",
      options: {
        filterOptions: {
          names: priorityOptions.map((priority) => priority.value),
        },
        customBodyRender: (value) => {
          if (value === "VIP") {
            return <span className={classes.p1}>{value}</span>;
          } else if (value === "Affiliate") {
            return <span className={classes.p2}>{value}</span>;
          } else if (value === "Regular") {
            return <span className={classes.p3}>{value}</span>;
          }
        },
      },
    },
    {
      name: "Status",
      options: {
        filterOptions: {
          names: statusOptions.map((status) => status.value),
        },
      },
    },
    {
      name: "Description",
      options: {
        filter: false,
      },
    },
    {
      name: "Assignedby ID",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Assigned By",
      options: {
        filterOptions: {
          names: props.agents.map((item) => item.name),
        },
      },
    },
    {
      name: "Assignedto Lead ID",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Assigned To Lead",
      options: {
        filter: false,
      },
    },
    {
      name: "Assignedto Agent ID",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Assigned To Agent",
      options: {
        filterOptions: {
          names: props.financeAgents.map((item) => item.name),
        },
      },
    },
    {
      name: "Closed by ID",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Closed By",
      options: {
        filterOptions: {
          names: props.financeAgents.map((item) => item.name),
        },
      },
    },
    {
      name: "Amount",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "UTR Number",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Bank Name",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "IFSC Code",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Account Holder Name",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Account Number",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Images",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Created At",
      options: {
        filter: false,
      },
    },
    {
      name: "Closed At",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        print: false,
        filter: false,
        download: false,
        customBodyRender: (ticket_id, values) => {
          return (
            <IconButton
              color="primary"
              size="medium"
              aria-label={ticket_id}
              onClick={(e) => {
                setUpdateD(values.rowData);
                setOpenUpdateModal(true);
              }}
            >
              <img
                decode="async"
                alt="Action"
                longdesc="https://aonetechnology.in/"
                loading="lazy"
                referrerPolicy="no-referrer"
                height={20}
                width={20}
                src="/images/icons/ticket/action.svg"
              />
            </IconButton>
          );
        },
      },
    },
    {
      name: "ID",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "PDF",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
  ];

  const options = {
    // filterType: "multiselect",
    print: false,
    download: false,
    selectableRows: "none",
    rowsPerPageOptions: [5, 10, 15, 20],
    rowsPerPage: pageSize,
    page: currentPage - 1,
    count: totalCount,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Sorry, there is no data to display"
        ),
      },
    },
    customToolbar: () => (
      <>
        <IconButton
          aria-label="Search Options"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <ArrowDropDownCircleOutlinedIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
          style={{ margin: "9rem 3rem 0rem -3rem" }}
        >
          <div className={classes.searchMenu}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setTicketID(event.target.checked);
                  }}
                />
              }
              label="Ticket ID"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setUserID(event.target.checked);
                  }}
                />
              }
              label="User ID"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setUsername(event.target.checked);
                  }}
                />
              }
              label="Username"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setPhone(event.target.checked);
                  }}
                />
              }
              label="Mobile Number"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setUtr(event.target.checked);
                  }}
                />
              }
              label="UTR Number"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setMasterId(event.target.checked);
                  }}
                />
              }
              label="Master ID"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setBankName(event.target.checked);
                  }}
                />
              }
              label="Bank Name"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setIfsc(event.target.checked);
                  }}
                />
              }
              label="IFSC Code"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setAccountHolder(event.target.checked);
                  }}
                />
              }
              label="Account Holder Name"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setAccountNumber(event.target.checked);
                  }}
                />
              }
              label="Account Number"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setDescription(event.target.checked);
                  }}
                />
              }
              label="Description"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setCreated(event.target.checked);
                  }}
                />
              }
              label="Created At"
            />
          </div>
        </Menu>
        <IconButton aria-label="Download" onClick={ticketDownload}>
          <CloudDownloadIcon />
        </IconButton>
        <IconButton aria-label="Refresh" onClick={ticketDataRefresh}>
          <RefreshIcon />
        </IconButton>
      </>
    ),
    onChangePage: (page) => {
      setCurrentPage(page + 1);
    },
    onChangeRowsPerPage: (pageLimit) => {
      setPageSize(pageLimit);
    },
    onFilterChange: (filterList, filterData) => {
      setPlayerPlatform(filterData[5]);
      setCategory(filterData[7]);
      setPriority(filterData[9]);
      setStatus(filterData[10]);
      setAssignedTo(filterData[17]);
      setAssignedBy(filterData[13]);
      setClosedBy(filterData[19]);
    },
    onSearchChange: (searchText) => {
      searchText === null ? setSearchText("") : setSearchText(searchText);
    },
    // customToolbar: () => (
    //   <IconButton aria-label="Add" onClick={handleOpen}>
    //     <AddIcon />
    //   </IconButton>
    // ),
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      {/* <AddTicketModal open={openAddModal} onClose={handleClose} /> */}
      <SimpleReactLightbox>
        <Snackbar
          open={loadingSnackbar}
          autoHideDuration={15000}
          onClose={handleLoadingSnackbarClose}
        >
          <Alert onClose={handleLoadingSnackbarClose} severity="info">
            Downloading Ticket Details...
          </Alert>
        </Snackbar>
        <Snackbar
          open={successSnackbar}
          autoHideDuration={500}
          onClose={handleSuccessSnackbarClose}
        >
          <Alert onClose={handleSuccessSnackbarClose} severity="success">
            Successfully Downloaded.
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorSnackbar}
          autoHideDuration={3000}
          onClose={handleErrorSnackbarClose}
        >
          <Alert onClose={handleErrorSnackbarClose} severity="error">
            Oops! Please try again.
          </Alert>
        </Snackbar>
        <TicketDetailsModal
          open={openDataModal}
          onClose={handleCloseData}
          rowData={rowD}
        />
        <UpdateTicketDetails
          open={openUpdateModal}
          onClose={handleCloseUpdate}
          updateData={updateD}
          role={props.role}
          updatedby_id={props.updatedby_id}
          updatedby_name={props.updatedby_name}
          onUpdate={(data) => {
            setOpenUpdateModal(false);
            ticketDataRefresh();
          }}
          onDelete={(data) => {
            setOpenUpdateModal(false);
            ticketDataRefresh();
          }}
        />
        {typeof window !== "undefined" && (
          <MUIDataTable
            title={"Ticket Details"}
            data={
              !loading
                ? ticketData.map((ticket) => {
                    return [
                      ticket.ticket_id,
                      ticket.user_id,
                      ticket.username,
                      ticket.phone,
                      ticket.language,
                      ticket.player_platform ? ticket.player_platform : "",
                      ticket.master_id ? ticket.master_id : "",
                      ticket.category ? ticket.category : "",
                      (ticket.category === "Deposit Request" &&
                        ticket.bank[0] &&
                        ticket.bank[0].bank_name !== "-") ||
                      (ticket.category === "OP Deposit" &&
                        ticket.bank[0] &&
                        ticket.bank[0].bank_name !== "-") ||
                      (ticket.category === "New ID" &&
                        ticket.bank[0] &&
                        ticket.bank[0].bank_name !== "-")
                        ? ticket.bank[0].bank_name
                        : "",
                      ticket.priority,
                      ticket.status,
                      ticket.description,
                      ticket.assignedby_id,
                      ticket.assignedby_name,
                      ticket.assignedto_lead_id,
                      ticket.assignedto_lead_name,
                      ticket.assignedto_agent_id,
                      ticket.assignedto_agent_name,
                      ticket.closedby_agent_id ? ticket.closedby_agent_id : "",
                      ticket.closedby_agent_name
                        ? ticket.closedby_agent_name
                        : "",
                      ticket.bank[0] ? ticket.bank[0].amount : "",
                      ticket.bank[0] ? ticket.bank[0].utr_number : "",
                      ticket.bank[0] ? ticket.bank[0].bank_name : "",
                      ticket.bank[0] ? ticket.bank[0].ifsc_code : "",
                      ticket.bank[0] ? ticket.bank[0].account_holder_name : "",
                      ticket.bank[0] ? ticket.bank[0].account_number : "",
                      ticket.images ? ticket.images : [],
                      ticket.readable_created_at
                        ? ticket.readable_created_at
                        : "",
                      ticket.readable_closed_at
                        ? ticket.readable_closed_at
                        : "",
                      ticket.ticket_id,
                      ticket._id,
                      ticket.pdf ? ticket.pdf : [],
                    ];
                  })
                : []
            }
            columns={columns}
            options={options}
          />
        )}
      </SimpleReactLightbox>
    </ThemeProvider>
  );
};

export default TicketTable;
