/* React Imports */

import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "next/router";

/* Component Imports */

import Header from "../Header";
import AgentNavbar from "./Navbar";
import CrmContainer from "../CrmContainer";

/* Data Imports */

import AppConfig from "../../../AppConfig";

const AgentLayout = (props) => {
  return (
    <React.Fragment>
      <div id="rootDiv">
        <Header
          keywords={
            props.keywords ? props.keywords : "design,material,ui,nextjs,react"
          }
          description={props.description ? props.description : "This is a CRM"}
          title={props.title ? props.title : "Aone Technology"}
          canonicalUrl={
            props.canonicalUrl
              ? props.canonicalUrl
              : AppConfig.urlPrefix + props.router.asPath.substring(1)
          }
        />
        <AgentNavbar
          user_id={props.user_id}
          username={props.username}
          role={props.role}
          assignedto_platform={props.assignedto_platform}
          agents={props.agents}
          financeAgents={props.financeAgents}
          handleRemoveCookies={props.handleRemoveCookies}
        />
        <Helmet>
          <html lang="en-GB" />
        </Helmet>
        <CrmContainer>{props.children}</CrmContainer>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AgentLayout);
