import React from "react";
import MUIDataTable from "mui-datatables";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import {
  IconButton,
  Button,
  CircularProgress,
  Snackbar,
  Checkbox,
  FormControlLabel,
  Menu,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import categoryOptions from "../agent/Leads/data/categoryOptions";
import playerOptions from "../agent/Leads/data/playerOptions";
import platformOptions from "../agent/Leads/data/platformOptions";
import statusOptions from "../agent/Leads/data/statusOptions";
import UpdateUserDetails from "../../page-sections/agent/dashboard/sub-components/UpdateUserDetails";
import UserDetailsModal from "../../page-sections/agent/dashboard/sub-components/UserDetailsModal";
import AppConfig from "../../../AppConfig";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  green: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2AE1C5",
    padding: "0.5rem",
    color: "#ffffff",
    borderRadius: "0.3rem",
  },
  red: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EA7575",
    padding: "0.5rem",
    color: "#ffffff",
    borderRadius: "0.3rem",
  },
  blue: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#57B9E5",
    padding: "0.5rem",
    color: "#ffffff",
    borderRadius: "0.3rem",
  },
  idButton: {
    color: "#727272",
    fontWeight: "400",
    fontSize: "1rem",
  },
  searchMenu: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "0.5rem 1rem 0.5rem 1rem",
  },
}));

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "#FAFAFA !important",
        },
      },
      MuiButton: {
        root: { color: "#4A4A4A", fontWeight: "600" },
      },
      MuiTableSortLabel: {
        root: {
          "&$active": {
            color: "#0D4972",
            "&& $icon": {
              color: "#0D4972",
            },
          },
        },
      },
      MUIDataTableBodyCell: {
        root: {
          color: "#727272",
          fontSize: "1rem",
        },
      },
      MuiPaper: { root: { color: "#0D4972" } },
      MUIDataTableToolbar: {
        root: {
          backgroundColor: "#EDEDED",
        },
      },
    },
  });

const UserTable = (props) => {
  const classes = useStyles();

  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(1);
  const [userData, setUserData] = React.useState([]);
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [openDataModal, setOpenDataModal] = React.useState(false);
  const [rowD, setRowD] = React.useState([]);
  const [updateD, setUpdateD] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [searchText, setSearchText] = React.useState("");
  const [category, setCategory] = React.useState([]);
  const [player, setPlayer] = React.useState([]);
  const [playerPlatform, setPlayerPlatform] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [id, setID] = React.useState(false);
  const [name, setName] = React.useState(false);
  const [dob, setDOB] = React.useState(false);
  const [email, setEmail] = React.useState(false);
  const [phone, setPhone] = React.useState(false);
  const [alternate, setAlternate] = React.useState(false);
  const [username, setUsername] = React.useState(false);
  const [masterId, setMasterId] = React.useState(false);
  const [city, setCity] = React.useState(false);
  const [pincode, setPincode] = React.useState(false);
  const [depositBank, setDepositBank] = React.useState(false);
  const [bankname, setBankName] = React.useState(false);
  const [ifsc, setIfsc] = React.useState(false);
  const [accountnumber, setAccountNumber] = React.useState(false);
  const [acountholder, setAccountHolder] = React.useState(false);
  const [addedBy, setAddedBy] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseUpdate = () => setOpenUpdateModal(false);

  const handleCloseData = () => setOpenDataModal(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetch(
        AppConfig.apiUrlPrefix +
          "team-lead-dashboard/users?page=" +
          currentPage +
          "&limit=" +
          pageSize +
          "&searchText=" +
          searchText +
          "&assignedto_lead_id=" +
          props.user_id +
          "&id=" +
          id +
          "&name=" +
          name +
          "&dob=" +
          dob +
          "&email=" +
          email +
          "&phone=" +
          phone +
          "&alternate=" +
          alternate +
          "&username=" +
          username +
          "&masterId=" +
          masterId +
          "&depositBank=" +
          depositBank +
          "&city=" +
          city +
          "&pincode=" +
          pincode +
          "&bankname=" +
          bankname +
          "&ifsc=" +
          ifsc +
          "&accountnumber=" +
          accountnumber +
          "&acountholder=" +
          acountholder,
        {
          cache: "no-store",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            category: category,
            player: player,
            playerPlatform: playerPlatform,
            status: status,
            addedBy: addedBy,
          }),
        }
      )
        .then((r) => r.json())
        .then((d) => {
          setUserData(d.UserData);
          setTotalCount(d.count);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    };
    fetchData();
  }, [
    currentPage,
    pageSize,
    searchText,
    category,
    player,
    playerPlatform,
    status,
    id,
    name,
    dob,
    email,
    phone,
    alternate,
    username,
    masterId,
    depositBank,
    city,
    pincode,
    bankname,
    ifsc,
    accountnumber,
    acountholder,
    addedBy,
  ]);

  const userDataRefresh = async () => {
    await fetch(
      AppConfig.apiUrlPrefix +
        "team-lead-dashboard/users?page=" +
        currentPage +
        "&limit=" +
        pageSize +
        "&searchText=" +
        searchText +
        "&assignedto_lead_id=" +
        props.user_id +
        "&id=" +
        id +
        "&name=" +
        name +
        "&dob=" +
        dob +
        "&email=" +
        email +
        "&phone=" +
        phone +
        "&alternate=" +
        alternate +
        "&username=" +
        username +
        "&masterId=" +
        masterId +
        "&depositBank=" +
        depositBank +
        "&city=" +
        city +
        "&pincode=" +
        pincode +
        "&bankname=" +
        bankname +
        "&ifsc=" +
        ifsc +
        "&accountnumber=" +
        accountnumber +
        "&acountholder=" +
        acountholder,
      {
        cache: "no-store",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          category: category,
          player: player,
          playerPlatform: playerPlatform,
          status: status,
          addedBy: addedBy,
        }),
      }
    )
      .then((r) => r.json())
      .then((d) => {
        setUserData(d.UserData);
        setTotalCount(d.count);
        setLoading(false);
      })
      .catch((e) => console.error(e));
  };

  const userDataDownload = async () => {
    const reqURL =
      AppConfig.apiUrlPrefix +
      "team-lead-dashboard/users-download?assignedto_lead_id=" +
      props.user_id +
      "&searchText=" +
      searchText +
      "&id=" +
      id +
      "&name=" +
      name +
      "&dob=" +
      dob +
      "&email=" +
      email +
      "&phone=" +
      phone +
      "&alternate=" +
      alternate +
      "&username=" +
      username +
      "&masterId=" +
      masterId +
      "&depositBank=" +
      depositBank +
      "&city=" +
      city +
      "&pincode=" +
      pincode +
      "&bankname=" +
      bankname +
      "&ifsc=" +
      ifsc +
      "&accountnumber=" +
      accountnumber +
      "&acountholder=" +
      acountholder;
    setLoadingSnackbar(true);
    const res = await fetch(reqURL, {
      cache: "no-store",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        category: category,
        status: status,
        player: player,
        playerPlatform: playerPlatform,
        addedBy: addedBy,
      }),
    });
    const response = await res.json();
    if (res.status === 200) {
      setLoadingSnackbar(false);
      setSuccessSnackbar(true);
      const link = document.createElement("a");
      link.href = response.objectLink;
      link.download = "users.csv";
      link.dispatchEvent(new MouseEvent("click"));
    } else {
      setLoadingSnackbar(false);
      setErrorSnackbar(true);
    }
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  const columns = [
    {
      name: "User ID",
      options: {
        filter: false,
        customBodyRender: (user_id, values) => {
          return (
            <Button
              aria-label="Info"
              className={classes.idButton}
              onClick={(e) => {
                setRowD(values.rowData);
                setOpenDataModal(true);
              }}
            >
              {user_id}
            </Button>
          );
        },
      },
    },
    {
      name: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Date of Birth",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Email ID",
      options: {
        filter: false,
      },
    },
    {
      name: "Mobile Number",
      options: {
        filter: false,
      },
    },
    {
      name: "Alternate Number",
      options: {
        filter: false,
      },
    },
    {
      name: "Call Schedule",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Language",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Category",
      options: {
        filterOptions: {
          names: categoryOptions.map((category) => category.value),
        },
      },
    },
    {
      name: "Player Type",
      options: {
        filterOptions: {
          names: playerOptions.map((player) => player.value),
        },
      },
    },
    {
      name: "Player Platform",
      options: {
        filterOptions: {
          names: platformOptions.map((platform) => platform.value),
        },
      },
    },
    {
      name: "Status",
      options: {
        customBodyRender: (value) => {
          if (value === "Account created") {
            return <span className={classes.green}>{value}</span>;
          } else if (value === "Active / Existing player") {
            return <span className={classes.green}>{value}</span>;
          } else if (value === "Busy") {
            return <span className={classes.red}>{value}</span>;
          } else if (value === "Call back later") {
            return <span className={classes.blue}>{value}</span>;
          } else if (value === "Created another account") {
            return <span className={classes.green}>{value}</span>;
          } else if (value === "Customer Disconnecting") {
            return <span className={classes.red}>{value}</span>;
          } else if (value === "Deposit done") {
            return <span className={classes.green}>{value}</span>;
          } else if (value === "DND") {
            return <span className={classes.red}>{value}</span>;
          } else if (value === "Details shared again") {
            return <span className={classes.blue}>{value}</span>;
          } else if (value === "Inactive player-converted") {
            return <span className={classes.green}>{value}</span>;
          } else if (value === "Interested") {
            return <span className={classes.green}>{value}</span>;
          } else if (value === "Invalid number") {
            return <span className={classes.red}>{value}</span>;
          } else if (value === "Link Sent") {
            return <span className={classes.blue}>{value}</span>;
          } else if (value === "RNR (ringing no response)") {
            return <span className={classes.red}>{value}</span>;
          } else if (value === "Switched off") {
            return <span className={classes.red}>{value}</span>;
          } else if (value === "Not Interested") {
            return <span className={classes.red}>{value}</span>;
          }
        },
        filterOptions: {
          names: statusOptions.map((status) => status.value),
        },
      },
    },
    {
      name: "City",
      options: {
        filter: false,
      },
    },
    {
      name: "Pincode",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Account Username",
      options: {
        filter: false,
      },
    },
    {
      name: "Master ID",
      options: {
        filter: false,
      },
    },
    {
      name: "Deposit Bank Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Message",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Added By",
      options: {
        filterOptions: {
          names: props.agents.map((item) => item.name),
        },
      },
    },
    {
      name: "Created At",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        print: false,
        filter: false,
        download: false,
        customBodyRender: (user_id, values) => {
          return (
            <IconButton
              color="primary"
              aria-label={user_id}
              size="medium"
              onClick={(e) => {
                setUpdateD(values.rowData);
                setOpenUpdateModal(true);
              }}
            >
              <img
                decode="async"
                alt="Action"
                longdesc="https://crmaonetechnology.in/"
                loading="lazy"
                referrerPolicy="no-referrer"
                height={20}
                width={20}
                src="/images/icons/dashboard/action.svg"
              />
            </IconButton>
          );
        },
      },
    },
    {
      name: "Bank Name 1",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "IFSC COde 1",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Account Holder Name 1",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Account Number 1",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Bank Name 2",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "IFSC COde 2",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Account Holder Name 2",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Account Number 2",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Addedby ID",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "ID",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    selectableRows: "none",
    rowsPerPageOptions: [5, 10, 15, 20],
    rowsPerPage: pageSize,
    page: currentPage - 1,
    count: totalCount,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Sorry, there is no data to display"
        ),
      },
    },
    customToolbar: () => (
      <>
        <IconButton
          aria-label="Search Options"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <ArrowDropDownCircleOutlinedIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
          style={{ margin: "9rem 3rem 0rem -3rem" }}
        >
          <div className={classes.searchMenu}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setID(event.target.checked);
                  }}
                />
              }
              label="User ID"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setName(event.target.checked);
                  }}
                />
              }
              label="Name"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setDOB(event.target.checked);
                  }}
                />
              }
              label="Date of Birth"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setEmail(event.target.checked);
                  }}
                />
              }
              label="Email ID"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setPhone(event.target.checked);
                  }}
                />
              }
              label="Mobile Number"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setAlternate(event.target.checked);
                  }}
                />
              }
              label="Alternate Number"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setUsername(event.target.checked);
                  }}
                />
              }
              label="Username"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setMasterId(event.target.checked);
                  }}
                />
              }
              label="Master ID"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setDepositBank(event.target.checked);
                  }}
                />
              }
              label="Deposit Bank Name"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setCity(event.target.checked);
                  }}
                />
              }
              label="City"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setPincode(event.target.checked);
                  }}
                />
              }
              label="Pincode"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setBankName(event.target.checked);
                  }}
                />
              }
              label="Bank Name"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setIfsc(event.target.checked);
                  }}
                />
              }
              label="IFSC Code"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setAccountHolder(event.target.checked);
                  }}
                />
              }
              label="Account Holder Name"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    setAccountNumber(event.target.checked);
                  }}
                />
              }
              label="Account Number"
            />
          </div>
        </Menu>
        <IconButton aria-label="Download" onClick={userDataDownload}>
          <CloudDownloadIcon />
        </IconButton>
        <IconButton aria-label="Refresh" onClick={userDataRefresh}>
          <RefreshIcon />
        </IconButton>
      </>
    ),
    onChangePage: (page) => {
      setCurrentPage(page + 1);
    },
    onChangeRowsPerPage: (pageLimit) => {
      setPageSize(pageLimit);
    },
    onFilterChange: (filterList, filterData) => {
      setCategory(filterData[8]);
      setPlayer(filterData[9]);
      setPlayerPlatform(filterData[10]);
      setStatus(filterData[11]);
      setAddedBy(filterData[18]);
    },
    onSearchChange: (searchText) => {
      searchText === null ? setSearchText("") : setSearchText(searchText);
    },
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={15000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Downloading User Details...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={500}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Successfully Downloaded.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          Oops! Please try again.
        </Alert>
      </Snackbar>
      <UserDetailsModal
        open={openDataModal}
        onClose={handleCloseData}
        rowData={rowD}
      />
      <UpdateUserDetails
        open={openUpdateModal}
        onClose={handleCloseUpdate}
        updateData={updateD}
        assignedby_id={props.assignedby_id}
        assignedby_name={props.assignedby_name}
        assignedto_platform={props.assignedto_platform}
        role={props.role}
        onUpdate={(data) => {
          setOpenUpdateModal(false);
          userDataRefresh();
        }}
        onDelete={(data) => {
          setOpenUpdateModal(false);
          userDataRefresh();
        }}
      />
      {typeof window !== "undefined" && (
        <MUIDataTable
          title={"User Details"}
          data={
            !loading
              ? userData.map((user) => {
                  return [
                    user.user_id,
                    user.name,
                    user.dob,
                    user.email,
                    user.phone,
                    user.alternate_phone,
                    user.readable_date ? user.readable_date : "",
                    user.language,
                    user.category,
                    user.player,
                    user.player_platform ? user.player_platform : "",
                    user.status,
                    user.city,
                    user.pincode,
                    user.account_username,
                    user.master_id,
                    user.deposit_bank,
                    user.message,
                    user.addedby_name,
                    user.readable_created_at ? user.readable_created_at : "",
                    user.user_id,
                    user.bank[0] ? user.bank[0].bank_name : "",
                    user.bank[0] ? user.bank[0].ifsc_code : "",
                    user.bank[0] ? user.bank[0].account_holder_name : "",
                    user.bank[0] ? user.bank[0].account_number : "",
                    user.bank[1] ? user.bank[1].bank_name : "",
                    user.bank[1] ? user.bank[1].ifsc_code : "",
                    user.bank[1] ? user.bank[1].account_holder_name : "",
                    user.bank[1] ? user.bank[1].account_number : "",
                    user.addedby_id,
                    user._id,
                  ];
                })
              : []
          }
          columns={columns}
          options={options}
        />
      )}
    </ThemeProvider>
  );
};

export default UserTable;
