import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import AddTeamLead from "../admin/new/sub-components/AddTeamLead";
import AddFinanceLead from "../admin/new/sub-components/AddFinanceLead";
import AddAgent from "../admin/new/sub-components/AddAgent";
import AddFinanceAgent from "../admin/new/sub-components/AddFinanceAgent";

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
}));

const AddNew = (props) => {
  const classes = useStyles();

  const [add, setAdd] = React.useState("add team lead");

  return (
    <React.Fragment>
      <RadioGroup
        aria-label="add"
        name="Add"
        value={add}
        onChange={(event) => {
          setAdd(event.target.value);
        }}
        className={classes.radioGroup}
      >
        <FormControlLabel
          value="add team lead"
          control={<Radio color="primary" />}
          label="Add Team Lead"
        />
        <FormControlLabel
          value="add finance lead"
          control={<Radio color="primary" />}
          label="Add Finance Lead"
        />
        <FormControlLabel
          value="add agent"
          control={<Radio color="primary" />}
          label="Add Agent"
        />
        <FormControlLabel
          value="add finance agent"
          control={<Radio color="primary" />}
          label="Add Finance Agent"
        />
      </RadioGroup>
      {add === "add team lead" ? (
        <AddTeamLead
          addedby_id={props.addedby_id}
          addedby_name={props.addedby_name}
          role={props.role}
        />
      ) : (
        <span></span>
      )}
      {add === "add finance lead" ? (
        <AddFinanceLead
          addedby_id={props.addedby_id}
          addedby_name={props.addedby_name}
          role={props.role}
        />
      ) : (
        <span></span>
      )}
      {add === "add agent" ? (
        <AddAgent
          addedby_id={props.addedby_id}
          addedby_name={props.addedby_name}
        />
      ) : (
        <span></span>
      )}
      {add === "add finance agent" ? (
        <AddFinanceAgent
          addedby_id={props.addedby_id}
          addedby_name={props.addedby_name}
        />
      ) : (
        <span></span>
      )}
    </React.Fragment>
  );
};

export default AddNew;
