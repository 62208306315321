const languageOptions = [
  { title: "Assamese", value: "Assamese" },
  { title: "Bengali", value: "Bengali" },
  { title: "Bodo", value: "Bodo" },
  { title: "Dogri", value: "Dogri" },
  { title: "English", value: "English" },
  { title: "Gujarati", value: "Gujarati" },
  { title: "Hindi", value: "Hindi" },
  { title: "Kannada", value: "Kannada" },
  { title: "Kashmiri", value: "Kashmiri" },
  { title: "Konkani", value: "Konkani" },
  { title: "Maithili", value: "Maithili" },
  { title: "Malayalam", value: "Malayalam" },
  { title: "Manipuri", value: "Manipuri" },
  { title: "Marathi", value: "Marathi" },
  { title: "Nepali", value: "Nepali" },
  { title: "Odia", value: "Odia" },
  { title: "Punjabi", value: "Punjabi" },
  { title: "Sanskrit", value: "Sanskrit" },
  { title: "Santali", value: "Santali" },
  { title: "Sindhi", value: "Sindhi" },
  { title: "Tamil", value: "Tamil" },
  { title: "Telugu", value: "Telugu" },
  { title: "Urdu", value: "Urdu" },
];

export default languageOptions;
