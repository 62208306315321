import React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { IconButton, CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import RefreshIcon from "@material-ui/icons/Refresh";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AppConfig from "../../../AppConfig";
import UpdateAgentModal from "../admin/dashboard/sub-components/UpdateAgentModal";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "#FAFAFA !important",
        },
      },
      MuiButton: {
        root: { color: "#4A4A4A", fontWeight: "600" },
      },
      MuiTableSortLabel: {
        root: {
          "&$active": {
            color: "#0D4972",
            "&& $icon": {
              color: "#0D4972",
            },
          },
        },
      },
      MUIDataTableBodyCell: {
        root: {
          color: "#727272",
          fontSize: "1rem",
        },
      },
      MuiPaper: { root: { color: "#0D4972" } },
      MUIDataTableToolbar: {
        root: {
          backgroundColor: "#EDEDED",
        },
      },
    },
  });

const AgentTable = (props) => {
  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [agentData, setAgentData] = React.useState([[]]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(1);
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [updateD, setUpdateD] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const handleCloseUpdate = () => setOpenUpdateModal(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetch(
        AppConfig.apiUrlPrefix +
          "manager-dashboard/agents?page" +
          currentPage +
          "&limit=" +
          pageSize +
          "&assignedto_manager_id=" +
          props.user_id +
          "&searchText=" +
          searchText,
        {
          cache: "no-store",
        }
      )
        .then((r) => r.json())
        .then((d) => {
          setAgentData(d.agentsData);
          setTotalCount(d.count);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    };
    fetchData();
  }, [currentPage, pageSize, searchText]);

  const agentDataRefresh = async () => {
    setLoading(true);
    await fetch(
      AppConfig.apiUrlPrefix +
        "manager-dashboard/team-leads?page" +
        currentPage +
        "&limit=" +
        pageSize +
        "&assignedto_manager_id=" +
        props.user_id +
        "&searchText=" +
        searchText,
      {
        cache: "no-store",
      }
    )
      .then((r) => r.json())
      .then((d) => {
        setAgentData(d.agentsData);
        setTotalCount(d.count);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  const agentDataDownload = async () => {
    const reqURL =
      AppConfig.apiUrlPrefix +
      "manager-dashboard/agents-download?searchText=" +
      searchText +
      "&assignedto_manager_id=" +
      props.user_id;
    setLoadingSnackbar(true);
    const res = await fetch(reqURL, {
      cache: "no-store",
    });
    const response = await res.json();
    if (res.status === 200) {
      setLoadingSnackbar(false);
      setSuccessSnackbar(true);
      const link = document.createElement("a");
      link.href = response.objectLink;
      link.download = "agents.csv";
      link.dispatchEvent(new MouseEvent("click"));
    } else {
      setLoadingSnackbar(false);
      setErrorSnackbar(true);
    }
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  const columns = [
    "Agent ID",
    "Name",
    "Designation",
    "Email ID",
    "Mobile Number",
    "Password",
    "Assigned To Platform",
    {
      name: "Assigned To Lead ID",
      options: {
        display: false,
        viewColumns: false,
      },
    },
    "Assigned To Lead Name",
    {
      name: "Platform",
      options: {
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Addedby ID",
      options: {
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Addedby Name",
      options: {
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Action",
      options: {
        print: false,
        filter: false,
        download: false,
        customBodyRender: (id, values) => {
          return (
            <IconButton
              color="primary"
              size="medium"
              aria-label={id}
              onClick={(e) => {
                setUpdateD(values.rowData);
                setOpenUpdateModal(true);
              }}
            >
              <img
                decode="async"
                alt="Action"
                longdesc="https://aonetechnology.in/"
                loading="lazy"
                referrerPolicy="no-referrer"
                height={20}
                width={20}
                src="/images/icons/ticket/action.svg"
              />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    print: false,
    filter: false,
    download: false,
    selectableRows: "none",
    rowsPerPageOptions: [5, 10, 15, 20],
    rowsPerPage: pageSize,
    page: currentPage - 1,
    count: totalCount,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Sorry, there is no data to display"
        ),
      },
    },
    customToolbar: () => (
      <>
        <IconButton aria-label="Download" onClick={agentDataDownload}>
          <CloudDownloadIcon />
        </IconButton>
        <IconButton aria-label="Refresh" onClick={agentDataRefresh}>
          <RefreshIcon />
        </IconButton>
      </>
    ),
    onChangePage: (page) => {
      setCurrentPage(page + 1);
    },
    onChangeRowsPerPage: (pageLimit) => {
      setPageSize(pageLimit);
    },
    onSearchChange: (searchText) => {
      searchText === null ? setSearchText("") : setSearchText(searchText);
    },
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={15000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Downloading Agent Details...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={500}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Successfully Downloaded.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          Oops! Please try again.
        </Alert>
      </Snackbar>
      <UpdateAgentModal
        open={openUpdateModal}
        onClose={handleCloseUpdate}
        updateData={updateD}
        onUpdate={(data) => {
          setOpenUpdateModal(false);
          agentDataRefresh();
        }}
        onDelete={(data) => {
          setOpenUpdateModal(false);
          agentDataRefresh();
        }}
      />
      {typeof window !== "undefined" && (
        <MUIDataTable
          title={"Agent Details"}
          data={
            !loading
              ? agentData.map((agent) => {
                  return [
                    agent.id,
                    agent.name,
                    agent.designation,
                    agent.email,
                    agent.phone,
                    agent.password,
                    agent.assignedto_platform ? agent.assignedto_platform : "",
                    agent.assignedto_lead_id,
                    agent.assignedto_lead_name,
                    agent.platform ? agent.platform : [],
                    agent.addedby_id,
                    agent.addedby_name,
                    agent.id,
                  ];
                })
              : []
          }
          columns={columns}
          options={options}
        />
      )}
    </ThemeProvider>
  );
};

export default AgentTable;
