/* Common Imports */

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Typography, Tab, Tabs, Box } from "@material-ui/core";
import ManagerTable from "./ManagerTable";
import TeamLeadTable from "./TeamLeadTable";
import AgentTable from "./AgentTable";
import UserTable from "./UserTable";
import TicketTable from "./TicketTable";
import FinanceLeadTable from "./FinanceLeadTable";
import FinanceAgentTable from "./FinanceAgentTable";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "3rem",
  },
  sectionSubDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  tabsAndTitleContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "3rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginBottom: "2rem",
    },
  },
  tabsCombined: {
    background: "#ffffff",
    color: "#343434",
    borderRadius: "4px",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "& .MuiTab-root": {
      minWidth: "auto",
      padding: "0.5rem 1rem",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      background: "#0D4972",
      color: "#ffffff",
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiTabScrollButton-root.Mui-disabled": {
        display: "none",
      },
      width: "100%",
    },
  },
}));

/* Tab Panel MUI default Function */

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const DashboardTabs = (props) => {
  const classes = useStyles();

  /* Tab Change Handling */
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <React.Fragment>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionSubDiv}>
          <div className={classes.tabsAndTitleContainer}>
            <Tabs
              variant="scrollable"
              className={classes.tabsCombined}
              value={value}
              onChange={handleChange}
              aria-label="Dashboard Tabs"
              indicatorColor="primary"
              TabIndicatorProps={{
                style: {
                  height: 0,
                },
              }}
            >
              <Tab label="Managers" {...a11yProps(0)} />
              <Tab label="Team Leads" {...a11yProps(1)} />
              <Tab label="Agents" {...a11yProps(2)} />
              <Tab label="Finance Team Leads" {...a11yProps(3)} />
              <Tab label="Finance Team Agents" {...a11yProps(4)} />
              <Tab label="User Details" {...a11yProps(5)} />
              <Tab label="Ticket Details" {...a11yProps(6)} />
            </Tabs>
          </div>

          {/* Tabs Content */}
          <div style={{ width: "100%" }}>
            <TabPanel value={value} index={0}>
              <ManagerTable />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TeamLeadTable />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AgentTable />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <FinanceLeadTable />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <FinanceAgentTable />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <UserTable
                assignedby_id={props.assignedby_id}
                assignedby_name={props.assignedby_name}
                role={props.role}
              />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <TicketTable
                agents={props.agents}
                financeAgents={props.financeAgents}
                updatedby_id={props.assignedby_id}
                updatedby_name={props.assignedby_name}
                role={props.role}
              />
            </TabPanel>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardTabs;
