/* Common Imports */

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MuiIconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import platformOptions from "../../../agent/Leads/data/platformOptions";

/* Data Imports */

import AppConfig from "../../../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: "1rem",
    width: "100%",
  },
  heading: {
    width: "100%",
    color: "#0D4972",
    fontWeight: "400",
    fontSize: "2rem",
    lineHeight: "3rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "2rem",
  },
  formControl: { width: "100%" },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cancelButton: {
    color: "#FFFFFF",
    background: "#727272",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#727272",
    },
  },
  deleteButton: {
    color: "#FFFFFF",
    background: "#2AA9E1",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#2AA9E1",
    },
  },
  updateButton: {
    color: "#FFFFFF",
    background: "#0D4972",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#0D4972",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UpdateFinanceLeadModal = (props) => {
  const classes = useStyles();

  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [id, setID] = React.useState("");
  const [name, setName] = React.useState("");
  const [designation, setDesignation] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [assignedto_manager_id, setAssignedToManagerId] = React.useState("");
  const [assignedto_manager_name, setAssignedToManagerName] =
    React.useState("");
  const [assignedto_platform, setAssignedToPlatform] = React.useState("");
  const [manager, setManager] = React.useState([]);

  React.useEffect(async () => {
    const data = await (
      await fetch(AppConfig.apiUrlPrefix + "managers/manager-details", {
        cache: "no-store",
      })
    ).json();
    setManager(data);
  }, []);

  React.useEffect(() => {
    if (props.updateData !== undefined) {
      setID(props.updateData[0] ? props.updateData[0] : "");
      setName(props.updateData[1] ? props.updateData[1] : "");
      setDesignation(props.updateData[2] ? props.updateData[2] : "");
      setEmail(props.updateData[3] ? props.updateData[3] : "");
      setPhone(props.updateData[4] ? props.updateData[4] : "");
      setAssignedToPlatform(props.updateData[5] ? props.updateData[5] : "");
      setAssignedToManagerId(props.updateData[6] ? props.updateData[6] : "");
      setAssignedToManagerName(props.updateData[7] ? props.updateData[7] : "");
      setPassword(props.updateData[8] ? props.updateData[8] : "");
    }
  }, [props.updateData]);

  const submitUpdate = () => {
    const reqURL =
      AppConfig.apiUrlPrefix +
      "finance-lead/update-finance-lead-details?name=" +
      encodeURIComponent(name) +
      "&designation=" +
      encodeURIComponent(designation) +
      "&email=" +
      encodeURIComponent(email) +
      "&phone=" +
      encodeURIComponent(phone) +
      "&password=" +
      encodeURIComponent(password) +
      "&assignedto_manager_id=" +
      encodeURIComponent(assignedto_manager_id) +
      "&assignedto_manager_name=" +
      encodeURIComponent(assignedto_manager_name) +
      "&assignedto_platform=" +
      encodeURIComponent(assignedto_platform) +
      "&id=" +
      encodeURIComponent(id);
    setLoadingSnackbar(true);
    fetch(reqURL, { cache: "no-store" })
      .then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            setLoadingSnackbar(false);
            setSuccessSnackbar(true);
            typeof props.onUpdate === "function"
              ? props.onUpdate(data)
              : undefined;
          })
          .catch((error) => {
            console.log(error);
            setLoadingSnackbar(false);
            setErrorSnackbar(true);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoadingSnackbar(false);
        setErrorSnackbar(true);
      });
  };

  const onDelete = () => {
    const reqURL = encodeURI(
      AppConfig.apiUrlPrefix + "finance-lead/delete-finance-lead?id=" + id
    );
    fetch(reqURL, { cache: "no-store" })
      .then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            typeof props.onDelete === "function"
              ? props.onDelete(data)
              : undefined;
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  return (
    <React.Fragment>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={3000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Updating Details...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={500}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Successfully Updated.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          Oops! Something seems to have gone wrong!
        </Alert>
      </Snackbar>
      <Dialog
        className={classes.modal}
        onClose={props.onClose}
        aria-labelledby="update-finance-modal"
        open={props.open}
        maxWidth="lg"
      >
        <DialogTitle id="update-finance-modal-title" onClose={props.onClose}>
          <Typography variant="h5" component="p" className={classes.heading}>
            Update Finance Team Lead Details
          </Typography>
          <MuiIconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.onClose}
          >
            <CloseIcon />
          </MuiIconButton>
        </DialogTitle>
        <form method="GET" id="umf">
          <DialogContent dividers>
            <TextField
              id="name"
              name="name"
              label="Name"
              type="text"
              size="small"
              className={classes.textField}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <TextField
              id="designation"
              name="designation"
              label="Designation"
              type="text"
              size="small"
              className={classes.textField}
              value={designation}
              onChange={(event) => {
                setDesignation(event.target.value);
              }}
              disabled
            />
            <TextField
              id="email"
              name="email"
              label="Email ID"
              type="text"
              size="small"
              className={classes.textField}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <TextField
              id="phone"
              name="phone"
              label="Phone"
              type="text"
              size="small"
              className={classes.textField}
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
            />
            <TextField
              id="password"
              name="password"
              label="Password"
              type="text"
              size="small"
              className={classes.textField}
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <FormControl size="small" className={classes.formControl}>
              <InputLabel htmlFor="platform-assign-select">
                Assign To Platform
              </InputLabel>
              <Select
                native
                label="Assign To Platform"
                className={classes.textField}
                value={assignedto_platform}
                onChange={(event) => {
                  setAssignedToPlatform(event.target.value);
                }}
                disabled={props.role == "team lead" ? true : false}
              >
                {platformOptions.map((option, key) => (
                  <option key={key} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel htmlFor="assign-select">Assign To</InputLabel>
              <Select
                native
                label="Assign To"
                value={
                  assignedto_manager_id +
                  "/~*42069*~/" +
                  assignedto_manager_name
                }
                className={classes.textField}
                onChange={(event) => {
                  setAssignedToManagerId(
                    event.target.value.split("/~*42069*~/")[0]
                  );
                  setAssignedToManagerName(
                    event.target.value.split("/~*42069*~/")[1]
                  );
                }}
                disabled={props.role == "manager" ? true : false}
              >
                <option value=""></option>
                {manager.map((data, key) => (
                  <option key={key} value={data.id + "/~*42069*~/" + data.name}>
                    {data.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              label="Close"
              onClick={props.onClose}
              className={classes.cancelButton}
            >
              Close
            </Button>
            <Button
              variant="contained"
              label="Delete"
              onClick={onDelete}
              className={classes.deleteButton}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              title="Submit"
              onClick={submitUpdate}
              rel="noopener"
              className={classes.updateButton}
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default UpdateFinanceLeadModal;
