import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TextField,
  Typography,
  Button,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import AppConfig from "../../../../../AppConfig";
import platformOptions from "../../../agent/Leads/data/platformOptions";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "1rem",
    fontWeight: "600",
    color: "#0D4972",
    paddingBottom: "1rem",
  },
  textField: {
    marginBottom: "1rem",
    width: "100%",
  },
  paperStyle: {
    borderRadius: "10px",
    padding: "2rem 1.5rem 2rem 1.5rem",
    marginBottom: "1rem",
    width: "100%",
  },
  addForm: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    gap: "2rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  formControl: { width: "100%" },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "2rem",
  },
  cancelButton: {
    marginRight: "1rem",
    color: "#FFFFFF",
    background: "#727272",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#727272",
    },
  },
  createButton: {
    color: "#FFFFFF",
    background: "#0D4972",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#0D4972",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddFinanceAgent = (props) => {
  const classes = useStyles();

  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [name, setName] = React.useState("");
  const [designation, setDesignation] = React.useState("finance agent");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [assignedto_lead_id, setAssignedToLeadId] = React.useState("");
  const [assignedto_lead_name, setAssignedToLeadName] = React.useState("");
  const [assignedto_platform, setAssignedToPlatform] = React.useState("");
  const [financelead, setFinanceLead] = React.useState([]);
  const [addedby_id] = React.useState(props.addedby_id);
  const [addedby_name] = React.useState(props.addedby_name);
  // const [refreshToken, setRefreshToken] = React.useState(Math.random());

  const submitClear = () => {
    setName("");
    setEmail("");
    setPhone("");
    setPassword("");
  };

  React.useEffect(async () => {
    const data = await (
      await fetch(
        AppConfig.apiUrlPrefix + "finance-lead/finance-lead-details",
        {
          cache: "no-store",
        }
      )
    ).json();
    setFinanceLead(data);

    // const timer = setTimeout(() => setRefreshToken(Math.random()), 500);

    // return () => clearTimeout(timer);
  }, []);

  const submitCreate = () => {
    const reqURL =
      AppConfig.apiUrlPrefix +
      "finance-agent/store-finance-agent-details?name=" +
      encodeURIComponent(name) +
      "&designation=" +
      encodeURIComponent(designation) +
      "&email=" +
      encodeURIComponent(email) +
      "&phone=" +
      encodeURIComponent(phone) +
      "&password=" +
      encodeURIComponent(password) +
      "&assignedto_lead_id=" +
      (props.role == "finance lead"
        ? encodeURIComponent(addedby_id)
        : encodeURIComponent(assignedto_lead_id)) +
      "&assignedto_lead_name=" +
      (props.role == "finance lead"
        ? encodeURIComponent(addedby_name)
        : encodeURIComponent(assignedto_lead_name)) +
      "&assignedto_platform=" +
      encodeURIComponent(assignedto_platform) +
      "&addedby_id=" +
      encodeURIComponent(addedby_id) +
      "&addedby_name=" +
      encodeURIComponent(addedby_name);
    setLoadingSnackbar(true);
    fetch(reqURL, { cache: "no-store" })
      .then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            setLoadingSnackbar(false);
            setSuccessSnackbar(true);
          })
          .catch((error) => {
            console.log(error);
            setLoadingSnackbar(false);
            setErrorSnackbar(true);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoadingSnackbar(false);
        setErrorSnackbar(true);
      });
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  return (
    <React.Fragment>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={3000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Adding...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={500}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Added Successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          Oops! Something went wrong!
        </Alert>
      </Snackbar>

      <Paper elevation={2} className={classes.paperStyle}>
        <Typography variant="h6" component="div" className={classes.heading}>
          Add Finance Agent
        </Typography>
        <form id="user-form" method="GET">
          <div className={classes.addForm}>
            <TextField
              id="name"
              name="name"
              label="Name"
              required={true}
              variant="outlined"
              type="text"
              size="small"
              className={classes.textField}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <input
              id="designation"
              name="designation"
              label="Designation"
              type="text"
              value={designation}
              onChange={(event) => {
                setDesignation(event.target.value);
              }}
              hidden={true}
            />
            <TextField
              id="email"
              name="email"
              label="Email ID"
              variant="outlined"
              type="text"
              size="small"
              className={classes.textField}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <TextField
              id="phone"
              name="phone"
              label="Mobile Number"
              variant="outlined"
              type="text"
              size="small"
              className={classes.textField}
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
            />
          </div>
          <div className={classes.addForm}>
            <TextField
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              type="text"
              size="small"
              required={true}
              className={classes.textField}
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <InputLabel htmlFor="platform-assign-select">
                Assign To Platform
              </InputLabel>
              <Select
                native
                label="Assign To Platform"
                className={classes.textField}
                onChange={(event) => {
                  setAssignedToPlatform(event.target.value);
                }}
                disabled={props.role == "team lead" ? true : false}
              >
                <option value=""></option>
                {platformOptions.map((option, key) => (
                  <option key={key} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <InputLabel htmlFor="assign-select">Assign To</InputLabel>
              <Select
                native
                label="Assign To"
                className={classes.textField}
                onChange={(event) => {
                  setAssignedToLeadId(
                    event.target.value.split("/~*42069*~/")[0]
                  );
                  setAssignedToLeadName(
                    event.target.value.split("/~*42069*~/")[1]
                  );
                }}
                disabled={props.role == "finance lead" ? true : false}
              >
                <option value=""></option>
                {financelead.map((data, key) => (
                  <option key={key} value={data.id + "/~*42069*~/" + data.name}>
                    {data.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
        </form>
      </Paper>
      <div className={classes.buttons}>
        <Button
          variant="contained"
          title="Cancel"
          size="large"
          onClick={submitClear}
          rel="noopener"
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          title="Create"
          size="large"
          onClick={submitCreate}
          rel="noopener"
          className={classes.createButton}
        >
          Add
        </Button>
      </div>
    </React.Fragment>
  );
};

export default AddFinanceAgent;
