const ReadableDateString = (d) => {
  const week = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const date = new Date(d);
  return (
    week[date.getDay()] +
    " " +
    month[date.getMonth()] +
    " the " +
    date.getDate() +
    (date.getDate() == 1
      ? "st"
      : date.getDate() == 2
      ? "nd"
      : date.getDate() == 3
      ? "rd"
      : "th") +
    " " +
    date.getFullYear() +
    " at " +
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
    ":" +
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":" +
    (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()) +
    " (IST)"
  );
};
export default ReadableDateString;
