const statusOptions = [
  { title: "Account created", value: "Account created" },
  { title: "Active / Existing player", value: "Active / Existing player" },
  { title: "Busy", value: "Busy" },
  { title: "Call back later", value: "Call back later" },
  { title: "Created another account", value: "Created another account" },
  { title: "Customer Disconnecting", value: "Customer Disconnecting" },
  { title: "Deposit done", value: "Deposit done" },
  { title: "DND", value: "DND" },
  { title: "Details shared again", value: "Details shared again" },
  { title: "Inactive player-converted", value: "Inactive player-converted" },
  { title: "Interested", value: "Interested" },
  { title: "Invalid number", value: "Invalid number" },
  { title: "Link sent", value: "Link sent" },
  { title: "Not Interested", value: "Not Interested" },
  { title: "RNR (ringing no response)", value: "RNR (ringing no response)" },
  { title: "Switched off", value: "Switched off" },
];

export default statusOptions;
