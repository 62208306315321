import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Stepper, Step, StepButton, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  textColor: {
    color: "#0D4972",
    fontWeight: 600,
  },
}));

const UserStepper = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {props.updatesData.map((label, index) => (
          <Step key={index}>
            <StepButton onClick={handleStep(index)}>
              <Typography
                variant="body2"
                component="span"
                className={classes.text}
              >
                {label.updatedby_name ? label.updatedby_name : ""} &nbsp;
                {label.log_type ? label.log_type : ""} &nbsp;
                {label.name !== "" && label.name !== undefined ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Name : </span>
                    &quot;{label.name}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.dob !== "" && label.dob !== undefined ? (
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.textColor}
                  >
                    <span className={classes.textColor}>Date of Birth : </span>
                    &quot;{label.dob}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.phone !== "" && label.phone !== undefined ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Phone : </span>
                    &quot;{label.phone}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.alternate_phone !== "" &&
                label.alternate_phone !== undefined ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>
                      Alternate Number :
                    </span>
                    &quot;{label.alternate_phone}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.email !== "" && label.email !== undefined ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Email : </span>
                    &quot;{label.email}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.account_username !== undefined &&
                label.account_username !== "" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Username : </span>
                    &quot;{label.account_username}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.language !== undefined && label.language !== "" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Language : </span>
                    &quot;{label.language}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.category !== undefined && label.category !== "" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Category : </span>
                    &quot;{label.category}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.status !== undefined && label.status !== "" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Status : </span>
                    &quot;{label.status}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.player !== undefined && label.player !== "" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Player Type : </span>
                    &quot;{label.player}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.player_platform !== undefined &&
                label.player_platform !== "" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Player Platform :</span>
                    &quot;{label.player_platform}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.city !== undefined && label.city !== "" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>City : </span>
                    &quot;{label.city}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.pincode !== undefined && label.pincode !== "" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Pincode : </span>
                    &quot;{label.pincode}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.bank[0] !== undefined &&
                label.bank[0].bank_name !== "-" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Bank Name : </span>
                    &quot;{label.bank[0].bank_name}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.bank[0] !== undefined &&
                label.bank[0].ifsc_code !== "-" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>IFSC Code : </span>
                    &quot;{label.bank[0].ifsc_code}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.bank[0] !== undefined &&
                label.bank[0].account_holder_name !== "-" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>
                      Accout Holder Name :
                    </span>
                    &quot;{label.bank[0].account_holder_name}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.bank[0] !== undefined &&
                label.bank[0].account_number !== "-" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Accout Number : </span>
                    &quot;{label.bank[0].account_number}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.bank[1] !== undefined &&
                label.bank[1].bank_name !== "-" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Bank Name : </span>
                    &quot;{label.bank[1].bank_name}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.bank[1] !== undefined &&
                label.bank[1].ifsc_code !== "-" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>IFSC Code : </span>
                    &quot;{label.bank[1].ifsc_code}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.bank[1] !== undefined &&
                label.bank[1].account_holder_name !== "-" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>
                      Accout Holder Name :
                    </span>
                    &quot;{label.bank[1].account_holder_name}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.bank[1] !== undefined &&
                label.bank[1].account_number !== "-" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Accout Number : </span>
                    &quot;{label.bank[1].account_number}&quot;
                  </Typography>
                ) : null}
                &nbsp;
                {label.message !== undefined && label.message !== "" ? (
                  <Typography variant="body2" component="span">
                    <span className={classes.textColor}>Message : </span>
                    &quot;{label.message}&quot;
                  </Typography>
                ) : null}
              </Typography>
              &nbsp;
              <Typography
                variant="body2"
                component="span"
                className={classes.text}
              >
                {label.readable_updated_at ? label.readable_updated_at : ""}
              </Typography>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default UserStepper;
