const categoryOptions = [
  { title: "Deposit Request", value: "Deposit Request" },
  {
    title: "Withdrawal Request",
    value: "Withdrawal Request",
  },
  { title: "OP Deposit", value: "OP Deposit" },

  { title: "OP Withdrawal", value: "OP Withdrawal" },
  { title: "New ID", value: "New ID" },
  { title: "Change Password", value: "Change Password" },
  { title: "Change Bank Details", value: "Change Bank Details" },

  { title: "Block ID", value: "Block ID" },
  { title: "Unblock ID", value: "Unblock ID" },
  { title: "Bonus Not Added", value: "Bonus Not Added" },
  { title: "Agents CC", value: "Agents CC" },
  { title: "Player CC", value: "Player CC" },
  { title: "Betting History", value: "Betting History" },
  { title: "Betting Details", value: "Betting Details" },
  { title: "Bet Issues", value: "Bet Issues" },
  { title: "Auto Refund", value: "Auto Refund" },
  {
    title: "Others",
    value: "Others",
  },
];

export default categoryOptions;
