const statusOptions = [
  {
    title: "Open",
    value: "Open",
  },
  { title: "Closed", value: "Closed" },
  { title: "Pending Approval", value: "Pending Approval" },
  { title: "Invalid", value: "Invalid" },
];

export default statusOptions;
