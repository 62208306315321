/* Common Imports */

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core";
import MuiIconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import UserStepper from "../../../../stepper/UserStepper";
import AppConfig from "../../../../../AppConfig";

/* Data Imports */

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: "1rem",
    width: "100%",
  },
  heading: {
    width: "100%",
    color: "#0D4972",
    fontWeight: "400",
    fontSize: "2rem",
    lineHeight: "3rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  subHeading: {
    color: "#0D4972",
  },
  infoText: {
    width: "100%",
    color: "#727272",
    fontWeight: "600",
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    marginBottom: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
  },
  subText: {
    fontSize: "0.8rem",
    fontWeight: "600",
    color: "#EA7575",
    paddingBottom: "1rem",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cancelButton: {
    color: "#FFFFFF",
    background: "#727272",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#727272",
    },
  },
}));

const UserDetailsModal = (props) => {
  const classes = useStyles();

  const [userUpdates, setUserUpdates] = React.useState([]);

  React.useEffect(async () => {
    if (props.rowData[30] !== undefined) {
      const updates = await (
        await fetch(
          AppConfig.apiUrlPrefix + "user-update-logs/" + props.rowData[30],
          {
            cache: "no-store",
          }
        )
      ).json();
      setUserUpdates(updates);
    }
  }, [props.rowData[30]]);

  return (
    <React.Fragment>
      <Dialog
        className={classes.modal}
        onClose={props.onClose}
        aria-labelledby="user-details-modal"
        open={props.open}
        maxWidth="lg"
      >
        <DialogTitle id="user-details-modal-title" onClose={props.onClose}>
          <Typography variant="h5" component="p" className={classes.heading}>
            User Details
          </Typography>
          <MuiIconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.onClose}
          >
            <CloseIcon />
          </MuiIconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>User ID : </span>
            {props.rowData[0]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Name : </span>
            {props.rowData[1]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Date of Birth : </span>
            {props.rowData[2]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Email ID : </span>
            {props.rowData[3]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Mobile Number : </span>
            {props.rowData[4]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Alternate Number : </span>
            {props.rowData[5]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Call Schedule : </span>
            {props.rowData[6]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Language : </span>
            {props.rowData[7]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Category : </span>
            {props.rowData[8]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Player Type :</span>{" "}
            {props.rowData[9]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Player Platform :</span>{" "}
            {props.rowData[10]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Status : </span>
            {props.rowData[11]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>City : </span>
            {props.rowData[12]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Pincode : </span>
            {props.rowData[13]}
          </Typography>

          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Account Username : </span>
            {props.rowData[14]}
          </Typography>

          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Master ID : </span>
            {props.rowData[15]}
          </Typography>

          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Deposit Bank Name : </span>
            {props.rowData[16]}
          </Typography>

          <Typography variant="h6" component="div" className={classes.subText}>
            Bank Details - 1
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Bank Name : </span>
            {props.rowData[21]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>IFSC Code : </span>
            {props.rowData[22]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Account Holder Name : </span>
            {props.rowData[23]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Account Number : </span>
            {props.rowData[24]}
          </Typography>
          <Typography variant="h6" component="div" className={classes.subText}>
            Bank Details - 2
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Bank Name : </span>
            {props.rowData[25]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>IFSC Code : </span>
            {props.rowData[26]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Account Holder Name : </span>
            {props.rowData[27]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Account Number : </span>
            {props.rowData[28]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Message : </span>
            {props.rowData[17]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Added By : </span>
            {props.rowData[18]}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.infoText}
          >
            <span className={classes.subHeading}>Created At : </span>
            {props.rowData[19]}
          </Typography>
          <UserStepper updatesData={userUpdates} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            label="Close"
            onClick={props.onClose}
            className={classes.cancelButton}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default UserDetailsModal;
