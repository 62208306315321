/* Common Imports */

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppConfig from "../../../AppConfig";

/* Component Imports */

import { Typography, Tab, Tabs, Box, Button } from "@material-ui/core";
import StatusCard from "../../cards/StatusCard";
import AgentTable from "./AgentTable";
import UserTable from "./UserTable";
import TicketTable from "./TicketTable";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.only("sm")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.only("md")]: {
      flexWrap: "wrap",
    },
    width: "100%",
    gap: "0.5rem",
    paddingBottom: "2rem",
  },
  total: {
    background: "#2AA9E1",
    textTransform: "none",
  },
  pending: {
    backgroundColor: "#EA7575",
    textTransform: "none",
  },
  closed: {
    backgroundColor: "#2AE194",
    textTransform: "none",
  },
  open: {
    background: "#EA9D75",
    textTransform: "none",
  },
  invalid: {
    background: "#808080",
    textTransform: "none",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  sectionSubDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  tabsAndTitleContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "3rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginBottom: "2rem",
    },
  },
  tabsCombined: {
    background: "#ffffff",
    color: "#343434",
    borderRadius: "4px",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "& .MuiTab-root": {
      minWidth: "auto",
      padding: "0.5rem 1rem",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      background: "#0D4972",
      color: "#ffffff",
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiTabScrollButton-root.Mui-disabled": {
        display: "none",
      },
      width: "100%",
    },
  },
}));

/* Tab Panel MUI default Function */

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const DashboardTabs = (props) => {
  const classes = useStyles();

  const [totalTickets, setTotalTickets] = React.useState(0);
  const [pendingTickets, setPendingTickets] = React.useState(0);
  const [invalidTickets, setInvalidTickets] = React.useState(0);
  const [closedTickets, setClosedTickets] = React.useState(0);
  const [openTickets, setOpenTickets] = React.useState(0);
  const [pending, setPending] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [closed, setClosed] = React.useState(false);
  const [total, setTotal] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);
  // const [refreshToken, setRefreshToken] = React.useState(Math.random());

  /* Tab Change Handling */
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(async () => {
    const {
      total_tickets,
      pending_tickets,
      closed_tickets,
      open_tickets,
      invalid_tickets,
    } = await (
      await fetch(
        AppConfig.apiUrlPrefix +
          "team-lead-dashboard/tickets?assignedto_lead_id=" +
          props.user_id,
        {
          cache: "no-store",
        }
      )
    ).json();
    setTotalTickets(total_tickets);
    setPendingTickets(pending_tickets);
    setClosedTickets(closed_tickets);
    setOpenTickets(open_tickets);
    setInvalidTickets(invalid_tickets);
    // const timer = setTimeout(() => setRefreshToken(Math.random()), 30000);

    // return () => clearTimeout(timer);
  }, []);

  return (
    <React.Fragment>
      <div className={classes.flex}>
        <Button
          onClick={(event) => {
            setTotal(true);
            setOpen(false);
            setPending(false);
            setInvalid(false);
            setClosed(false);
            setValue(2);
          }}
        >
          <StatusCard
            title="Total Tickets"
            subtitle={totalTickets}
            icon="/images/icons/ticket/ticket.svg"
            cardClass={classes.total}
          />
        </Button>
        <Button
          onClick={(event) => {
            setOpen(true);
            setPending(false);
            setClosed(false);
            setInvalid(false);
            setTotal(false);
            setValue(2);
          }}
        >
          <StatusCard
            title="Open Tickets"
            subtitle={openTickets}
            icon="/images/icons/ticket/ticket.svg"
            cardClass={classes.open}
          />
        </Button>
        <Button
          onClick={(event) => {
            setPending(true);
            setOpen(false);
            setClosed(false);
            setInvalid(false);
            setTotal(false);
            setValue(2);
          }}
        >
          <StatusCard
            title="Pending Tickets"
            subtitle={pendingTickets}
            icon="/images/icons/ticket/ticket.svg"
            cardClass={classes.pending}
          />
        </Button>
        <Button
          onClick={(event) => {
            setInvalid(true);
            setPending(false);
            setOpen(false);
            setClosed(false);
            setTotal(false);
            setValue(2);
          }}
        >
          <StatusCard
            title="Invalid Tickets"
            subtitle={invalidTickets}
            icon="/images/icons/ticket/ticket.svg"
            cardClass={classes.invalid}
          />
        </Button>
        <Button
          onClick={(event) => {
            setClosed(true);
            setOpen(false);
            setPending(false);
            setTotal(false);
            setInvalid(false);
            setValue(2);
          }}
        >
          <StatusCard
            title="Closed Tickets"
            subtitle={closedTickets}
            icon="/images/icons/ticket/ticket.svg"
            cardClass={classes.closed}
          />
        </Button>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionSubDiv}>
          <div className={classes.tabsAndTitleContainer}>
            <Tabs
              variant="scrollable"
              className={classes.tabsCombined}
              value={value}
              onChange={handleChange}
              aria-label="Dashboard Tabs"
              indicatorColor="primary"
              TabIndicatorProps={{
                style: {
                  height: 0,
                },
              }}
            >
              <Tab label="Agent Details" {...a11yProps(0)} />
              <Tab label="User Details" {...a11yProps(1)} />
              <Tab label="Ticket Details" {...a11yProps(2)} />
            </Tabs>
          </div>

          {/* Tabs Content */}
          <div style={{ width: "100%" }}>
            <TabPanel value={value} index={0}>
              <AgentTable user_id={props.user_id} role={props.role} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <UserTable
                user_id={props.user_id}
                assignedby_id={props.assignedby_id}
                assignedby_name={props.assignedby_name}
                assignedto_platform={props.assignedto_platform}
                agents={props.agents}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TicketTable
                user_id={props.user_id}
                username={props.assignedby_name}
                role={props.role}
                assignedto_platform={props.assignedto_platform}
                financeAgents={props.financeAgents}
                pending={pending}
                open={open}
                closed={closed}
                total={total}
                invalid={invalid}
                onTicketChange={() => {
                  setPending(false);
                  setOpen(false);
                  setClosed(false);
                  setTotal(false);
                  setInvalid(false);
                }}
                onTicketNumbersChange={(
                  totalTickets,
                  pendingTickets,
                  invalidTickets,
                  closedTickets,
                  openTickets
                ) => {
                  setTotalTickets(totalTickets);
                  setPendingTickets(pendingTickets);
                  setInvalidTickets(invalidTickets);
                  setClosedTickets(closedTickets);
                  setOpenTickets(openTickets);
                }}
              />
            </TabPanel>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardTabs;
