/* Common Imports */

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
  Snackbar,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import AppConfig from "../../../../../AppConfig";
import { DropzoneAreaBase } from "material-ui-dropzone";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import categoryOptions from "../../tickets/data/categoryOptions";
import priorityOptions from "../../tickets/data/priorityOptions";
import languageOptions from "../../Leads/data/languageOptions";
import platformOptions from "../../Leads/data/platformOptions";
import { SRLWrapper } from "simple-react-lightbox";

const options = {
  buttons: {
    iconPadding: "10px",
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: true,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: false,
    size: "45px",
  },
  thumbnails: {
    showThumbnails: true,
  },
  settings: {
    boxShadow: "none",
    disableKeyboardControls: true,
    disablePanzoom: false,
    disableWheelControls: true,
    hideControlsAfter: 3000,
    overlayColor: "rgba(30, 30, 30, 0.9)",
    usingPreact: false,
  },
};

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: "1rem",
    width: "100%",
  },
  heading: {
    width: "100%",
    color: "#0D4972",
    fontWeight: "400",
    fontSize: "2rem",
    lineHeight: "3rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "2rem",
  },
  textFieldHalf: {
    width: "100%",
    margin: "0rem 0rem 2rem 0rem",
    [theme.breakpoints.up("md")]: {
      width: "48%",
    },
  },
  row: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  imageContainer: {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    gap: "1rem",
    cursor: "pointer",
  },
  imageDelete: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cancelButton: {
    color: "#FFFFFF",
    background: "#727272",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#727272",
    },
  },
  createButton: {
    color: "#FFFFFF",
    background: "#0D4972",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#0D4972",
    },
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  formControl: { width: "100%" },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreateTicket = (props) => {
  const classes = useStyles();

  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [userID, setUserID] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const [playerPlatform, setPlayerPlatform] = React.useState("");
  const [priority, setPriority] = React.useState("");
  const [status, setStatus] = React.useState("Open");
  const [masterId, setMasterId] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [utr, setUtr] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [ifsc, setIfsc] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [accountHolderName, setAccountHolderName] = React.useState("");
  const [bank, setBank] = React.useState("Account 1");
  const [description, setDescription] = React.useState("");
  const [upload, setUpload] = React.useState("image");
  const [pdfUrl, setPdfUrl] = React.useState([]);
  const [url, setUrl] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState(
    "Oops! Something seems to have gone wrong!!"
  );

  React.useEffect(() => {
    if (bank === "Account 1" && props.aN1 !== undefined && props.aN1 !== null) {
      setBankName(props.bN1 ? props.bN1 : "");
      setIfsc(props.ifsc1 ? props.ifsc1 : "");
      setAccountHolderName(props.aHN1 ? props.aHN1 : "");
      setAccountNumber(props.aN1 ? props.aN1 : "");
    }
    if (bank === "Account 2" && props.aN2 !== undefined && props.aN2 !== null) {
      setBankName(props.bN2 ? props.bN2 : "");
      setIfsc(props.ifsc2 ? props.ifsc2 : "");
      setAccountHolderName(props.aHN2 ? props.aHN2 : "");
      setAccountNumber(props.aN2 ? props.aN2 : "");
    }
    if (
      category === "Deposit Request" ||
      category === "OP Deposit" ||
      category === "New ID"
    ) {
      setBankName(props.depositBank ? props.depositBank : "");
    }
  }, [props.user_id, bank, category]);

  React.useEffect(() => {
    if (props.user_id !== undefined) {
      setUserID(props.user_id ? props.user_id : "");
      setUsername(props.username ? props.username : "");
      setPhone(props.phone ? props.phone : "");
      setLanguage(props.language ? props.language : "");
      setMasterId(props.masterId ? props.masterId : "");
      setPlayerPlatform(props.playerPlatform ? props.playerPlatform : "");
      setPriority(props.priority ? props.priority : "");
    }
  }, [props.user_id]);

  const clearFields = () => {
    // setUserID("");
    // setUsername("");
    // setPhone("");
    // setLanguage("");
    // setPlayerPlatform("");
    // setPriority("");
    setStatus("Open");
    setCategory("");
    setAmount("");
    setUtr("");
    // setBankName("");
    // setIfsc("");
    // setAccountNumber("");
    // setAccountHolderName("");
    setDescription("");
    setUrl([]);
    setPdfUrl([]);
  };

  const submitCreate = () => {
    const reqURL = encodeURI(
      AppConfig.apiUrlPrefix +
        "tickets/store-ticket-details?user_id=" +
        userID +
        "&assignedby_id=" +
        props.assignedby_id +
        "&assignedby_name=" +
        props.assignedby_name +
        "&username=" +
        username +
        "&phone=" +
        phone +
        "&masterId=" +
        masterId +
        "&language=" +
        language +
        "&player_platform=" +
        playerPlatform +
        "&category=" +
        category +
        "&priority=" +
        priority +
        "&status=" +
        status +
        "&amount=" +
        amount +
        "&utr=" +
        utr +
        "&bankName=" +
        bankName +
        "&ifsc=" +
        ifsc +
        "&accountNumber=" +
        accountNumber +
        "&accountHolderName=" +
        accountHolderName +
        "&description=" +
        description
    );
    setLoadingSnackbar(true);
    fetch(reqURL, {
      cache: "no-store",
      body: JSON.stringify({ images: url, pdf: pdfUrl }),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (data.status == "success") {
              setLoadingSnackbar(false);
              setSuccessSnackbar(true);
              clearFields();
              typeof props.onAdd === "function" ? props.onAdd(data) : undefined;
            } else if (data.status == "failure") {
              setErrorMsg(data.error);
              setLoadingSnackbar(false);
              setErrorSnackbar(true);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoadingSnackbar(false);
            setErrorSnackbar(true);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoadingSnackbar(false);
        setErrorSnackbar(true);
      });
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  const handleFileSelect = async (filey) => {
    if (filey.length > 0) {
      const files = filey[0].file;
      const formData = new FormData();
      formData.append("files", files);
      const res = (
        await (
          await fetch(AppConfig.apiUrlPrefix + "tickets/upload-image", {
            method: "POST",
            body: formData,
          })
        ).json()
      ).objectLink;
      setUrl((url) => [...url, res]);
    }
  };

  const handlePdfSelect = async (filey) => {
    if (filey.length > 0) {
      const files = filey[0].file;
      const formData = new FormData();
      formData.append("files", files);
      const res = (
        await (
          await fetch(AppConfig.apiUrlPrefix + "tickets/upload-pdf", {
            method: "POST",
            body: formData,
          })
        ).json()
      ).objectLink;
      setPdfUrl((pdfUrl) => [...pdfUrl, res]);
    }
  };

  return (
    <React.Fragment>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={3000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Creating Ticket...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={500}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Successfully Created.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="warning">
          {errorMsg}
        </Alert>
      </Snackbar>
      <Dialog
        className={classes.modal}
        onClose={props.onClose}
        aria-labelledby="create-ticket-modal"
        open={props.open}
        maxWidth="lg"
      >
        <DialogTitle id="create-ticket-modal-title" onClose={props.onClose}>
          <Typography variant="h5" component="p" className={classes.heading}>
            Create New Ticket
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              props.onClose();
              clearFields();
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form method="GET" id="atf">
          <DialogContent dividers>
            <TextField
              id="assigned_by"
              name="assigned_by"
              label="Assigned By"
              type="text"
              size="small"
              className={classes.textField}
              value={props.assignedby_name}
              disabled
            />
            <TextField
              id="username"
              name="username"
              label="Username"
              type="text"
              size="small"
              className={classes.textField}
              required={true}
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
              }}
            />
            <TextField
              id="phone"
              name="phone"
              label="Phone Number"
              type="number"
              size="small"
              className={classes.textField}
              value={phone}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
              min={0}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
            />
            <TextField
              id="master id"
              name="master id"
              label="Master ID"
              type="text"
              size="small"
              className={classes.textField}
              value={masterId}
              onChange={(event) => {
                setMasterId(event.target.value);
              }}
            />
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="language-select">Language</InputLabel>
              <Select
                native
                label="Language"
                className={classes.textField}
                value={language}
                onChange={(event) => {
                  setLanguage(event.target.value);
                }}
              >
                <option value=""></option>
                {languageOptions.map((option) => (
                  <option key={option.title} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="player-platform-select">
                Player Platform
              </InputLabel>
              <Select
                native
                label="Player Platform"
                className={classes.textField}
                value={playerPlatform}
                onChange={(event) => {
                  setPlayerPlatform(event.target.value);
                }}
              >
                <option value=""></option>
                {platformOptions.map((option) => (
                  <option key={option.title} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small" required className={classes.formControl}>
              <InputLabel htmlFor="category-select">Category</InputLabel>
              <Select
                native
                label="Category"
                className={classes.textField}
                onChange={(event) => {
                  setCategory(event.target.value);
                }}
              >
                <option value=""></option>
                {categoryOptions.map((option, key) => (
                  <option key={key} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>

            {(category === "Withdrawal Request" &&
              bankName !== null &&
              bankName !== undefined &&
              bankName !== "") ||
            (category === "OP Withdrawal" &&
              bankName !== null &&
              bankName !== undefined &&
              bankName !== "") ? (
              <FormControl size="small" className={classes.formControl}>
                <InputLabel htmlFor="bank-select">
                  Select Bank Account
                </InputLabel>
                <Select
                  native
                  label="Select Bank Account"
                  required
                  className={classes.textField}
                  onChange={(event) => {
                    setBank(event.target.value);
                  }}
                >
                  <option value="Account 1">Account 1</option>
                  <option value="Account 2">Account 2</option>
                </Select>
              </FormControl>
            ) : null}

            {category === "Deposit Request" ||
            category === "OP Deposit" ||
            category === "New ID" ? (
              <div>
                <div className={classes.row}>
                  <TextField
                    id="amount"
                    name="amount"
                    label="Amount"
                    size="small"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={amount}
                    className={classes.textFieldHalf}
                    onChange={(event) => {
                      setAmount(event.target.value);
                    }}
                  />
                  <TextField
                    id="utr_number"
                    name="utr_number"
                    label="UTR Number"
                    type="text"
                    size="small"
                    value={utr}
                    className={classes.textFieldHalf}
                    onChange={(event) => {
                      setUtr(event.target.value.replace(/\s/g, ""));
                    }}
                  />
                </div>
                <TextField
                  id="bank_name"
                  name="bank_name"
                  label="Bank Name"
                  type="text"
                  size="small"
                  value={bankName}
                  className={classes.textField}
                  onChange={(event) => {
                    setBankName(event.target.value);
                  }}
                />
              </div>
            ) : null}

            {category === "Withdrawal Request" ||
            category === "OP Withdrawal" ? (
              <div>
                <TextField
                  id="amount"
                  name="amount"
                  label="Amount"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  size="small"
                  value={amount}
                  className={classes.textField}
                  onChange={(event) => {
                    setAmount(event.target.value);
                  }}
                />
                <div className={classes.row}>
                  <TextField
                    id="bank_name"
                    name="bank_name"
                    label="Bank Name"
                    type="text"
                    size="small"
                    value={bankName}
                    className={classes.textFieldHalf}
                    onChange={(event) => {
                      setBankName(event.target.value);
                    }}
                  />
                  <TextField
                    id="ifsc_code"
                    name="ifsc_code"
                    label="IFSC Code"
                    type="text"
                    size="small"
                    value={ifsc}
                    className={classes.textFieldHalf}
                    onChange={(event) => {
                      setIfsc(event.target.value);
                    }}
                  />
                </div>
                <div className={classes.row}>
                  <TextField
                    id="account_holder_name"
                    name="account_holder_name"
                    label="Account Holder Name"
                    type="text"
                    size="small"
                    value={accountHolderName}
                    className={classes.textFieldHalf}
                    onChange={(event) => {
                      setAccountHolderName(event.target.value);
                    }}
                  />
                  <TextField
                    id="account_number"
                    name="account_number"
                    label="Account Number"
                    type="text"
                    size="small"
                    value={accountNumber}
                    className={classes.textFieldHalf}
                    onChange={(event) => {
                      setAccountNumber(event.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}

            <FormControl size="small" className={classes.formControl}>
              <InputLabel htmlFor="priority-select">Priority</InputLabel>
              <Select
                native
                label="Priority"
                value={priority}
                className={classes.textField}
                onChange={(event) => {
                  setPriority(event.target.value);
                }}
              >
                <option value=""></option>
                {priorityOptions.map((option, key) => (
                  <option key={key} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="status"
              name="status"
              label="Status"
              type="text"
              size="small"
              value={status}
              className={classes.textField}
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              disabled
            />
            <TextField
              id="description"
              name="description"
              label="Description"
              type="text"
              size="small"
              className={classes.textField}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
            <RadioGroup
              aria-label="upload"
              name="Upload"
              value={upload}
              onChange={(event) => {
                setUpload(event.target.value);
              }}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value="image"
                control={<Radio color="primary" />}
                label="Image"
              />
              <FormControlLabel
                value="pdf"
                control={<Radio color="primary" />}
                label="PDF"
              />
            </RadioGroup>
            {upload === "image" ? (
              <DropzoneAreaBase
                filesLimit={1}
                acceptedFiles={["image/*"]}
                dropzoneText={"Drag and drop images here or click"}
                onAdd={handleFileSelect}
                maxFileSize={104857600}
                showPreviews={true}
              />
            ) : (
              <DropzoneAreaBase
                filesLimit={1}
                acceptedFiles={["application/pdf"]}
                dropzoneText={"Drag and drop files here or click"}
                onAdd={handlePdfSelect}
                maxFileSize={104857600}
                showPreviews={true}
              />
            )}
            <SRLWrapper options={options}>
              <div className={classes.imageContainer}>
                {url.length > 0
                  ? url.map((image, key) => (
                      <div key={key} className={classes.imageDelete}>
                        <img src={image} height="100px" width="100px" />
                        <IconButton
                          aria-label="delete"
                          onClick={(event) => {
                            setUrl(url.filter((item) => item !== image));
                          }}
                        >
                          <DeleteIcon fontSize="large" color="primary" />
                        </IconButton>
                      </div>
                    ))
                  : null}
              </div>
            </SRLWrapper>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              label="Close"
              onClick={() => {
                props.onClose();
                clearFields();
              }}
              className={classes.cancelButton}
            >
              Close
            </Button>
            <Button
              variant="contained"
              title="Submit"
              onClick={submitCreate}
              rel="noopener"
              className={classes.createButton}
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateTicket;
