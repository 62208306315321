/* Common Imports */

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MuiIconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import platformOptions from "../../../agent/Leads/data/platformOptions";

/* Data Imports */

import AppConfig from "../../../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: "1rem",
    width: "100%",
  },
  heading: {
    width: "100%",
    color: "#0D4972",
    fontWeight: "400",
    fontSize: "2rem",
    lineHeight: "3rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "2rem",
  },
  textFieldHalf: {
    width: "100%",
    marginBottom: "2rem",
    [theme.breakpoints.up("md")]: {
      width: "48%",
    },
  },
  platformRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  formControl: { width: "100%" },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cancelButton: {
    color: "#FFFFFF",
    background: "#727272",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#727272",
    },
  },
  deleteButton: {
    color: "#FFFFFF",
    background: "#2AA9E1",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#2AA9E1",
    },
  },
  updateButton: {
    color: "#FFFFFF",
    background: "#0D4972",
    textTransform: "none",
    "&:hover": {
      color: "#FFFFFF",
      background: "#0D4972",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UpdateAgentModal = (props) => {
  const classes = useStyles();

  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [id, setID] = React.useState("");
  const [name, setName] = React.useState("");
  const [designation, setDesignation] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [assignedto_lead_id, setAssignedToLeadId] = React.useState("");
  const [assignedto_lead_name, setAssignedToLeadName] = React.useState("");
  const [assignedto_platform, setAssignedToPlatform] = React.useState("");
  const [teamlead, setTeamLead] = React.useState([]);
  // const [p1, setP1] = React.useState("");
  // const [pl1, setPl1] = React.useState("");
  // const [p2, setP2] = React.useState("");
  // const [pl2, setPl2] = React.useState("");

  React.useEffect(async () => {
    const data = await (
      await fetch(AppConfig.apiUrlPrefix + "team-leads/team-lead-details", {
        cache: "no-store",
      })
    ).json();
    setTeamLead(data);
  }, []);

  React.useEffect(() => {
    if (props.updateData !== undefined) {
      setID(props.updateData[0] ? props.updateData[0] : "");
      setName(props.updateData[1] ? props.updateData[1] : "");
      setDesignation(props.updateData[2] ? props.updateData[2] : "");
      setEmail(props.updateData[3] ? props.updateData[3] : "");
      setPhone(props.updateData[4] ? props.updateData[4] : "");
      setPassword(props.updateData[5] ? props.updateData[5] : "");
      setAssignedToPlatform(props.updateData[6] ? props.updateData[6] : "");
      setAssignedToLeadId(props.updateData[7] ? props.updateData[7] : "");
      setAssignedToLeadName(props.updateData[8] ? props.updateData[8] : "");
      // setP1(props.updateData[9] ? props.updateData[9][0].title : "");
      // setPl1(props.updateData[9] ? props.updateData[9][0].value : "");
      // setP2(props.updateData[9] ? props.updateData[9][1].title : "");
      // setPl2(props.updateData[9] ? props.updateData[9][1].value : "");
    }
  }, [props.updateData]);

  const submitUpdate = () => {
    const reqURL =
      AppConfig.apiUrlPrefix +
      "agents/update-agent-details?name=" +
      encodeURIComponent(name) +
      "&designation=" +
      encodeURIComponent(designation) +
      "&email=" +
      encodeURIComponent(email) +
      "&phone=" +
      encodeURIComponent(phone) +
      "&password=" +
      encodeURIComponent(password) +
      "&assignedto_lead_id=" +
      encodeURIComponent(assignedto_lead_id) +
      "&assignedto_lead_name=" +
      encodeURIComponent(assignedto_lead_name) +
      "&assignedto_platform=" +
      encodeURIComponent(assignedto_platform) +
      // "&p1=" +
      // encodeURIComponent(p1) +
      // "&pl1=" +
      // encodeURIComponent(pl1) +
      // "&p2=" +
      // encodeURIComponent(p2) +
      // "&pl2=" +
      // encodeURIComponent(pl2) +
      "&id=" +
      encodeURIComponent(id);
    setLoadingSnackbar(true);
    fetch(reqURL, { cache: "no-store" })
      .then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            setLoadingSnackbar(false);
            setSuccessSnackbar(true);
            typeof props.onUpdate === "function"
              ? props.onUpdate(data)
              : undefined;
          })
          .catch((error) => {
            console.log(error);
            setLoadingSnackbar(false);
            setErrorSnackbar(true);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoadingSnackbar(false);
        setErrorSnackbar(true);
      });
  };

  const onDelete = () => {
    const reqURL = encodeURI(
      AppConfig.apiUrlPrefix + "agents/delete-agent-details?id=" + id
    );
    fetch(reqURL, { cache: "no-store" })
      .then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            typeof props.onDelete === "function"
              ? props.onDelete(data)
              : undefined;
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  return (
    <React.Fragment>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={3000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Updating Agent Details...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={500}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Successfully Updated.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          Oops! Something seems to have gone wrong!
        </Alert>
      </Snackbar>
      <Dialog
        className={classes.modal}
        onClose={props.onClose}
        aria-labelledby="update-agent-modal"
        open={props.open}
        maxWidth="lg"
      >
        <DialogTitle id="update-agent-modal-title" onClose={props.onClose}>
          <Typography variant="h5" component="p" className={classes.heading}>
            Update Agent Details
          </Typography>
          <MuiIconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.onClose}
          >
            <CloseIcon />
          </MuiIconButton>
        </DialogTitle>
        <form method="GET" id="umf">
          <DialogContent dividers>
            <TextField
              id="name"
              name="name"
              label="Name"
              type="text"
              size="small"
              className={classes.textField}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <TextField
              id="designation"
              name="designation"
              label="Designation"
              type="text"
              size="small"
              className={classes.textField}
              value={designation}
              onChange={(event) => {
                setDesignation(event.target.value);
              }}
              disabled
            />
            <TextField
              id="email"
              name="email"
              label="Email ID"
              type="text"
              size="small"
              className={classes.textField}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <TextField
              id="phone"
              name="phone"
              label="Phone"
              type="text"
              size="small"
              className={classes.textField}
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
            />
            <TextField
              id="password"
              name="password"
              label="Password"
              type="text"
              size="small"
              className={classes.textField}
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <FormControl size="small" className={classes.formControl}>
              <InputLabel htmlFor="platform-assign-select">
                Assign To Platform
              </InputLabel>
              <Select
                native
                label="Assign To Platform"
                className={classes.textField}
                value={assignedto_platform}
                onChange={(event) => {
                  setAssignedToPlatform(event.target.value);
                }}
                disabled={props.role == "team lead" ? true : false}
              >
                {platformOptions.map((option, key) => (
                  <option key={key} value={option.value}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel htmlFor="assign-select">Assign To</InputLabel>
              <Select
                native
                label="Assign To"
                className={classes.textField}
                value={
                  assignedto_lead_id + "/~*42069*~/" + assignedto_lead_name
                }
                onChange={(event) => {
                  setAssignedToLeadId(
                    event.target.value.split("/~*42069*~/")[0]
                  );
                  setAssignedToLeadName(
                    event.target.value.split("/~*42069*~/")[1]
                  );
                }}
                disabled={props.role == "team lead" ? true : false}
              >
                <option value=""></option>
                {teamlead.map((data, key) => (
                  <option key={key} value={data.id + "/~*42069*~/" + data.name}>
                    {data.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            {/* <div className={classes.platformRow}>
              <TextField
                id="platform1"
                name="platform1"
                label="platform 1"
                type="text"
                size="small"
                className={classes.textFieldHalf}
                value={p1}
                onChange={(event) => {
                  setP1(event.target.value);
                }}
              />
              <TextField
                id="platformlink1"
                name="platformlink1"
                label="Link"
                type="text"
                size="small"
                className={classes.textFieldHalf}
                value={pl1}
                onChange={(event) => {
                  setPl1(event.target.value);
                }}
              />
            </div>
            <div className={classes.platformRow}>
              <TextField
                id="platform2"
                name="platform2"
                label="platform 2"
                type="text"
                size="small"
                className={classes.textFieldHalf}
                value={p2}
                onChange={(event) => {
                  setP2(event.target.value);
                }}
              />
              <TextField
                id="platformlink2"
                name="platformlink2"
                label="Link"
                type="text"
                size="small"
                className={classes.textFieldHalf}
                value={pl2}
                onChange={(event) => {
                  setPl2(event.target.value);
                }}
              />
            </div> */}
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              label="Close"
              onClick={props.onClose}
              className={classes.cancelButton}
            >
              Close
            </Button>
            {props.role == "team lead" ? null : (
              <Button
                variant="contained"
                label="Delete"
                onClick={onDelete}
                className={classes.deleteButton}
              >
                Delete
              </Button>
            )}
            <Button
              variant="contained"
              title="Submit"
              onClick={submitUpdate}
              rel="noopener"
              className={classes.updateButton}
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default UpdateAgentModal;
